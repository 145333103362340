import ExerciseWhyImportantCoparent, { ExerciseWhyImportantCoparent_title, ExerciseWhyImportantCoparent_title_en, ExerciseWhyImportantCoparent_image } from './ExerciseWhyImportantCoparent';
import ExerciseBuildTeam, { ExerciseBuildTeam_title, ExerciseBuildTeam_title_en, ExerciseBuildTeam_image } from './ExerciseBuildTeam';
import ExerciseDifficultQuestions, { ExerciseDifficultQuestions_title, ExerciseDifficultQuestions_title_en, ExerciseDifficultQuestions_image } from './ExerciseDifficultQuestions';
import ExerciseWhyCoparent, { ExerciseWhyCoparent_title, ExerciseWhyCoparent_title_en, ExerciseWhyCoparent_image } from './ExerciseWhyCoparent';
import ExerciseControl, { ExerciseControl_title, ExerciseControl_title_en, ExerciseControl_image } from './ExerciseControl';
import ExerciseChildReaction, { ExerciseChildReaction_title, ExerciseChildReaction_title_en, ExerciseChildReaction_image } from './ExerciseChildReaction';
import ExerciseHowAreYou, { ExerciseHowAreYou_title, ExerciseHowAreYou_title_en, ExerciseHowAreYou_image } from './ExerciseHowAreYou';
import ExerciseCozyMoments, { ExerciseCozyMoments_title, ExerciseCozyMoments_title_en, ExerciseCozyMoments_image } from './ExerciseCozyMoments';
import ExerciseLiftYourCoparent, { ExerciseLiftYourCoparent_title, ExerciseLiftYourCoparent_title_en, ExerciseLiftYourCoparent_image } from './ExerciseLiftYourCoparent';
import ExerciseLoss, { ExerciseLoss_title, ExerciseLoss_title_en, ExerciseLoss_image } from './ExerciseLoss';
import ExerciseCheckIn, { ExerciseCheckIn_title, ExerciseCheckIn_title_en, ExerciseCheckIn_image } from './ExerciseCheckIn';
import ExerciseDifferences, { ExerciseDifferences_title, ExerciseDifferences_title_en, ExerciseDifferences_image } from './ExerciseDifferences';
import ExerciseLifeChanges, { ExerciseLifeChanges_title, ExerciseLifeChanges_title_en, ExerciseLifeChanges_image } from './ExerciseLifeChanges';
import ExerciseChildAnger, { ExerciseChildAnger_title, ExerciseChildAnger_title_en, ExerciseChildAnger_image } from './ExerciseChildAnger';
import ExerciseChildSchoolwork, { ExerciseChildSchoolwork_title, ExerciseChildSchoolwork_title_en, ExerciseChildSchoolwork_image } from './ExerciseChildSchoolwork';
import ExerciseChildTeenagers, { ExerciseChildTeenagers_title, ExerciseChildTeenagers_title_en, ExerciseChildTeenagers_image } from './ExerciseChildTeenagers';


export interface IExercise {
    exercise: (props:IExerciseProps) => JSX.Element;
    slug:string;
    title: string;
    title_en: string;
    imageUrl:string;
    pointsMe: number;
    pointsCoparenting: number;
    pointsChild:number;
    articles: string[];
}

export interface IExerciseProps {
    cancel:()=>void;
    submit:(response:string)=>void;
}

export const exercies:IExercise[] = [
    {
        exercise: ExerciseWhyImportantCoparent ,
        slug:'why-important-coparenting',
        title: ExerciseWhyImportantCoparent_title,
        title_en: ExerciseWhyImportantCoparent_title_en,
        imageUrl:ExerciseWhyImportantCoparent_image,
        pointsMe: 0,
        pointsCoparenting: 10,
        pointsChild:0,

        articles: ['varfor-ar-ett-bra-foraldrasamarbete-viktigt','barnet-forst','the-child-comes-first','why-parental-cooperation-is-important'],
    },
    {
        exercise: ExerciseBuildTeam ,
        slug:'sa-bygger-ni-ett-foraldrateam',
        title: ExerciseBuildTeam_title,
        title_en: ExerciseBuildTeam_title_en,
        imageUrl:ExerciseBuildTeam_image,
        pointsMe: 1,
        pointsCoparenting: 10,
        pointsChild:5,
        articles: ['sa-bygger-ni-ett-foraldrateam','how-to-build-a-coparenting-team'],
    },
    {
        exercise: ExerciseDifficultQuestions ,
        slug:'difficult-questions',
        title: ExerciseDifficultQuestions_title,
        title_en: ExerciseDifficultQuestions_title_en,
        imageUrl:ExerciseDifficultQuestions_image,
        pointsMe: 4,
        pointsCoparenting: 8,
        pointsChild:8,
        articles: ['viktigt-att-prata-med-barnen-om-separationen', 'nar-en-foralder-forsvinner-ur-barnets-liv', 'sa-vinner-du-barnets-fortroende', 'svara-fragor-vad-svarar-man', 
        'when-a-parent-disappears-from-the-childs-life','how-to-win-your-childs-trust','tough-questions-what-to-answer','do-not-delay-telling-your-children-about-the-separation'],
    },
    {
        exercise: ExerciseWhyCoparent ,
        slug:'varfor-ar-ett-bra-foraldrasamarbete-viktigt',
        title: ExerciseWhyCoparent_title,
        title_en: ExerciseWhyCoparent_title_en,
        imageUrl:ExerciseWhyCoparent_image,
        pointsMe: 1,
        pointsCoparenting: 10,
        pointsChild:5,
        articles: ['varfor-ar-ett-bra-foraldrasamarbete-viktigt','why-parental-cooperation-is-important'],
    },
    {
        exercise: ExerciseControl ,
        slug:'behover-du-kliva-fram-eller-slappa-kontrollen',
        title: ExerciseControl_title,
        title_en: ExerciseControl_title_en,
        imageUrl:ExerciseControl_image,
        pointsMe: 7,
        pointsCoparenting: 10,
        pointsChild:2,
        articles: ['behover-du-kliva-fram-eller-slappa-kontrollen','do-you-need-to-step-up-or-let-go-of-control'],
    },
    {
        exercise: ExerciseChildReaction ,
        slug:'sa-kan-ert-barn-reagera',
        title: ExerciseChildReaction_title,
        title_en: ExerciseChildReaction_title_en,
        imageUrl:ExerciseChildReaction_image,
        pointsMe: 0,
        pointsCoparenting: 3,
        pointsChild:10,

        articles: ['sa-kan-ert-barn-reagera','childrens-reactions-to-separation-and-divorce','om-oro-sorg-och-nedstamdhet','the-childs-sadness-worry-and-depression'],
    },
    {
        exercise: ExerciseHowAreYou ,
        slug:'how-are-you',
        title: ExerciseHowAreYou_title,
        title_en: ExerciseHowAreYou_title_en,
        imageUrl:ExerciseHowAreYou_image,
        pointsMe: 8,
        pointsCoparenting: 0,
        pointsChild:0,

        articles: ['det-ar-en-kris-man-gar-igenom','separation-is-a-crisis'],
    },
    {
        exercise: ExerciseCozyMoments ,
        slug:'special-moments',
        title: ExerciseCozyMoments_title,
        title_en: ExerciseCozyMoments_title_en,
        imageUrl:ExerciseCozyMoments_image,
        pointsMe: 11,
        pointsCoparenting: 0,
        pointsChild:3,

        articles: ['sa-kan-du-finnas-till-hands-for-barnet','how-to-support-your-child'],
    },
    {
        exercise: ExerciseLiftYourCoparent ,
        slug:'praise-coparent',
        title: ExerciseLiftYourCoparent_title,
        title_en: ExerciseLiftYourCoparent_title_en,
        imageUrl:ExerciseLiftYourCoparent_image,
        pointsMe: 0,
        pointsCoparenting: 5,
        pointsChild:10,

        articles: ['sa-kan-du-finnas-till-hands-for-barnet', 'sa-bygger-ni-ett-foraldrateam','how-to-support-your-child','how-to-build-a-coparenting-team'],
    },
    {
        exercise: ExerciseLoss ,
        slug:'experience-loss',
        title: ExerciseLoss_title,
        title_en: ExerciseLoss_title_en,
        imageUrl:ExerciseLoss_image,
        pointsMe: 10,
        pointsCoparenting: 0,
        pointsChild:0,

        articles: ['separationen-kan-leda-till-kanslor-av-forlust','separation-can-cause-feelings-of-loss'],
    },
    {
        exercise: ExerciseCheckIn ,
        slug:'check-in',
        title: ExerciseCheckIn_title,
        title_en: ExerciseCheckIn_title_en,
        imageUrl:ExerciseCheckIn_image,
        pointsMe: 10,
        pointsCoparenting: 0,
        pointsChild:0,

        articles: ['foralder-ta-hand-om-dig-sjalv','parent-take-care-of-yourself',],
    },
    {
        exercise: ExerciseDifferences ,
        slug:'differences-in-parenting',
        title: ExerciseDifferences_title,
        title_en: ExerciseDifferences_title_en,
        imageUrl:ExerciseDifferences_image,
        pointsMe: 8,
        pointsCoparenting: 8,
        pointsChild:0,

        articles: ['utgangspunkter-for-samarbete-och-tydliga-granser','vardet-av-gemensamma-rutiner','new-kind-of-parenting','the-value-of-common-routines'],
    },
    {
        exercise: ExerciseLifeChanges ,
        slug:'sa-kan-vi-ta-hand-om-oss-sjalva-i-en-kris',
        title: ExerciseLifeChanges_title,
        title_en: ExerciseLifeChanges_title_en,
        imageUrl:ExerciseLifeChanges_image,
        pointsMe: 10,
        pointsCoparenting: 0,
        pointsChild:0,

        articles: ['sa-kan-vi-ta-hand-om-oss-sjalva-i-en-kris','self-care-in-a-crisis'],
    },
    {
        exercise: ExerciseChildTeenagers ,
        slug:'teenagers',
        title: ExerciseChildTeenagers_title,
        title_en: ExerciseChildTeenagers_title_en,
        imageUrl:ExerciseChildTeenagers_image,
        pointsMe: 0,
        pointsCoparenting: 0,
        pointsChild:10,

        articles: ['tonaringar-som-faller-mellan-stolarna','teenagers-need-special-attention-from-both-parents'],
    },
    {
        exercise: ExerciseChildAnger ,
        slug:'child-anger',
        title: ExerciseChildAnger_title,
        title_en: ExerciseChildAnger_title_en,
        imageUrl:ExerciseChildAnger_image,
        pointsMe: 0,
        pointsCoparenting: 0,
        pointsChild:10,

        articles: ['om-barns-ilska-och-utbrott','childrens-anger-and-tantrums'],
    },
    {
        exercise: ExerciseChildSchoolwork ,
        slug:'schoolwork',
        title: ExerciseChildSchoolwork_title,
        title_en: ExerciseChildSchoolwork_title_en,
        imageUrl:ExerciseChildSchoolwork_image,
        pointsMe: 0,
        pointsCoparenting: 0,
        pointsChild:10,

        articles: ['nar-skolarbetet-paverkas-pa-grund-av-separationen','if-schoolwork-is-affected'],
    },
];