import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:string,
  reflectionTwo:string,
  reflectionThree:string,
}

export const ExerciseChildAnger_title = "Barns ilska och utbrott";
export const ExerciseChildAnger_title_en = "Children's anger and outbursts";
export const ExerciseChildAnger_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/arg.jpg?fit=509%2C339&ssl=1";

const baselanguage = {

  introOne: "Föräldrars separation innebär förluster för barnen av olika slag och det kan göra att barn känner ilska eller irritabilitet.",
  introTwo: "Ilska kan vara maskerad sorg. Det vill säga, det kan vara lättare för barnet att visa ilska än sorg över separationen.",
  introThree: "Försök att inte gå i försvar om du blir anklagad. Bekräfta istället barnets känslor.",
  introFour: "Fortsätt söka kontakt, stå ut med ilskan och var fortsatt tillgänglig och erbjud närhet och gemensamma aktiviteter.",
  introFive: "Små stunder med barnet gör stor skillnad, som fem minuter vid middagsbordet efter maten för att prata lite.",
  introSix: "Låt samvaron med barnet stå i fokus utan att det ska leda någonstans, utan måsten.",

  questionOne :"Vad tänker du om det som står i sammanfattningen ovan? Stämmer något av det in på ert barn?",
  questionTwo :"Har ert barn upplevt förluster? Om ja, vilka?",
  questionThree :"Vilka gemensamma aktiviteter, utan måsten, skulle vara bra för er tror du?",
}

const engelska: (typeof baselanguage) = {
  introOne:"The separation of parents means losses for children of different types and it can make children feel angry or irritable.",
  introTwo:"Anger can be masked sadness. That is, it may be easier for the child to show anger than sadness over the separation.",
  introThree:"Try not to defend yourself if you are accused. Instead, confirm the child's feelings.",
  introFour:"Continue to seek contact, endure the anger and continue to be available and offer closeness and common activities.",
  introFive:"Small moments with the child make a big difference, like five minutes at the dinner table after dinner to talk a little.",
  introSix:"Let the interaction with the child focus without it leading anywhere, without obligations.",

  questionOne :"What do you think of the summary above? Does anything of it apply to your child?",
  questionTwo :"Has your child experienced losses? If yes, what?",
  questionThree :"What common activities, without obligations, would be good for you?",
}

export default function ExerciseChildAnger(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false,
    reflectionOne:"",
    reflectionTwo:"",
    reflectionThree:"",
  });
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <ul>
        <li>{lang.introOne}</li>
        <li>{lang.introTwo}</li>
        <li>{lang.introThree}</li>
        <li>{lang.introFour}</li>
        <li>{lang.introFive}</li>
        <li>{lang.introSix}</li>
      </ul>
      <h2>{lang.questionOne}</h2>
      <textarea rows={5} name="reflectionOne" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionOne} onChange={(e) => handleChangeTA(e)}></textarea>
      <h2>{lang.questionTwo}</h2>
      <textarea rows={5} name="reflectionTwo" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionTwo} onChange={(e) => handleChangeTA(e)}></textarea>
      <h2>{lang.questionThree}</h2>
      <textarea rows={5} name="reflectionThree" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionThree} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      {data.reflectionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionOne}</div>):null}
      <h2>{lang.questionTwo}</h2>
      {data.reflectionTwo?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionTwo}</div>):null}
      <h2>{lang.questionThree}</h2>
      {data.reflectionThree?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionThree}</div>):null}
    </>);

  return renderToStaticMarkup(reply);
}