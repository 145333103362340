import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:string,
}

export const ExerciseLiftYourCoparent_title = "Lyft varandra inför barnen";
export const ExerciseLiftYourCoparent_title_en = "Praise your coparent in front of the kids";
export const ExerciseLiftYourCoparent_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/istockphoto-1330084425-170667a.jpg?fit=509%2C339&ssl=1";

const baselanguage = {
  intro: 'Barn är påverkbara och känsliga för stämningar. Vi föräldrar kan trygga och glädja våra barn lite extra genom att samla på oss fina saker vi kan säga om barnets andra förälder. Det kanske känns fejk när vi själva är ledsna och sårade. Men det handlar om omtanke om barnet. För barn som kämpar med att älska sina föräldrar är det som balsam för själen att regelbundet få höra dig säga saker som:',
  quoteOne :"“Åh, nu trasade jag sönder pannkakan! Jag önskar jag kunde pappas knep - han är världsbäst på att göra pannkakor!”",
  quoteTwo :"“Tänk att både du och mamma har så starka och snabba ben. Det måste kännas skönt att springa så fort och bra som ni gör!”",
  quoteThree :"“Jag förstår om du vill att mamma också ska vara med på matchen. Hon är ju så bra på att heja!”",
  questionOne: 'Vilka fina omdömen om medföräldern skulle ditt barn kunna få höra från dig?',
}

const engelska: (typeof baselanguage) = {
  intro: "Children are impressionable and sensitive to moods. As parents, we can reassure and delight our children a little extra by gathering nice things to say about the other parent. It may feel fake when we ourselves are sad and hurt. But it's about caring for the child. For children who struggle to love their parents, it is like a balm to the soul to regularly hear you say things like:",
  quoteOne :"“Oh, crap I ruined the pancake! I wish I could do it as well as dad - he's the best at making pancakes!”",
  quoteTwo :"“Imagine both you and mom are such fast runners. It must feel good to run as fast and well as you do!”",
  quoteThree :"“I understand if you want mom to be at the game too. She's so good at cheering!”",
  questionOne: 'What nice things about the co-parent could your child hear from you?',
}

export default function ExerciseLiftYourCoparent(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false,
    reflectionOne:"",
  });
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <p>{lang.intro}</p>
      <p style={{margin: '20px', fontSize: '15px', fontStyle: 'italic'}} >{lang.quoteOne}</p>
      <p style={{margin: '20px', fontSize: '15px', fontStyle: 'italic'}} >{lang.quoteTwo}</p>
      <p style={{margin: '20px', fontSize: '15px', fontStyle: 'italic'}} >{lang.quoteThree}</p>
      <h2>{lang.questionOne}</h2>
      <textarea rows={5} name="reflectionOne" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionOne} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      {data.reflectionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionOne}</div>):null}
    </>);

  return renderToStaticMarkup(reply);
}