import React, { useEffect } from "react"
import LandingForgotPw from "./LandingForgotPw"
import LandingLogin from "./LandingLogin"
import LandingRegister from "./LandingRegister"
import "./landingStyles/ladningSignup.css"

interface IDisplayOption {
  selected: "none" | "login" | "signup" | "forgotpw" 
}

export default function LandingSignup() {
  const [state, setState] = React.useState<IDisplayOption>({ selected: "none" })

  const showModal = state.selected !== "none"

  const handleCloseModal = () => {
    setState({ selected: "none" })
  }

  const openLoginModal = () => {
    setState({ selected: "login" })
  }

  useEffect(() => {
    const handleOpenLoginModal = () => {
      setState({ selected: "login" })
    }

    window.addEventListener("openLoginModal", handleOpenLoginModal)

    return () => {
      window.removeEventListener("openLoginModal", handleOpenLoginModal)
    }
  }, [])

  useEffect(() => {
    const openModal = () => {
      setState({ selected: "signup" })
    }

    window.addEventListener("openSignupModal", openModal)

    return () => {
      window.removeEventListener("openSignupModal", openModal)
    }
  }, [])

  let componentToDisplay: JSX.Element

  if (state.selected === "login") {
    componentToDisplay = (
      <LandingLogin
        createAccount={()  => setState({ selected: "signup"})}
        forgotPassword={() => setState({ selected: "forgotpw" })}
        displayAllOptions={handleCloseModal}
      />
    )
  } else if (state.selected === "signup") {
    componentToDisplay = (
      <LandingRegister
        hasCode={false}
        displayAllOptions={handleCloseModal}
        openLoginModal={openLoginModal}
      />
    )
  } else if (state.selected === "forgotpw") {
    componentToDisplay = (
      <LandingForgotPw displayAllOptions={handleCloseModal} />
    )
  } else {
    componentToDisplay = <div />
  }

  return (
    <div
      className="muni-signup-modal"
      style={
        showModal ? { opacity: "1" } : { opacity: "0", pointerEvents: "none" }
      }
    >
      <div
        className="muni-signup-modal-close"
        onClick={() => setState({ selected: "none" })}
      >
        <img
          className="login-modal-close"
          src="./close300.svg"
          alt="closing icon"
        />
      </div>
      {componentToDisplay}
    </div>
  )
}
