import React, { createContext } from "react";
import { IUser, IUserCreate, IUserLogin, ICompletedExercise } from "service/user.types";
import axios from 'axios';
import Translate from "service/Translate";

const tn: Translate = Translate.getInstance();

export interface IContext {
  loggedIn: "LOGGED_IN"|"IN_PROGRESS"|"NOT_LOGGED_IN";
  user: IUser|null;
  readArticles: number[];
  completedExercises: ICompletedExercise[];
  lang: "EN"|"SE";
  statusMsg:string;
  statusDisplay:boolean;
  initiated:boolean;
  setLang:(lang:"SE"|"EN") => void;
  createUser:(data:IUserCreate) => void;
  login:(data:IUserLogin) => void;
  loginAnon:(token:string) => void;
  testToken:(token:string) => void;
  logout:() => void;
  readArticle:(articleId:number) => void;
  completedExercise:(exercise:ICompletedExercise) => void;
  forgotPassword:(data:{email:string}) => void;
}

interface IProps {
}

interface IState {
  loggedIn: "LOGGED_IN"|"IN_PROGRESS"|"NOT_LOGGED_IN";
  user: IUser|null;
  readArticles: number[];
  completedExercises: ICompletedExercise[];
  lang: "EN"|"SE";
  statusMsg:string;
  statusDisplay:boolean;
  initiated:boolean;
}

const API_URL = "https://api.varannanvecka.app:8081/v1/";
const defaultContext:IContext = {loggedIn:"IN_PROGRESS",lang:"SE",user:null,readArticles:[],statusMsg:"",statusDisplay:false,createUser:(data:IUserCreate) => {}, initiated: false, 
  login:(IUserLogin) =>{}, readArticle:(articleId) => {}, testToken:(data)=>{},logout:()=>{}, loginAnon:(token) =>{}, setLang:(token) =>{}, forgotPassword:(email) => {},
  completedExercises:[], completedExercise:(exercise) => {}};
const httpClient = axios.create({
  baseURL: ''
});
export const LoginContext = createContext<IContext>(defaultContext);

class LoginProvider extends React.Component<IProps, IState>  {
  
  private timer: any;
  constructor(props: IProps) {
    super(props);
    this.state ={
      loggedIn: defaultContext.loggedIn,
      user: defaultContext.user,
      readArticles: defaultContext.readArticles,
      completedExercises: defaultContext.completedExercises,
      lang:"SE",
      statusMsg:"",
      statusDisplay:false,
      initiated:false,
    };
  }

  public componentDidMount(): void {
    const user:string|null = localStorage.getItem("user");
    if (user !== null) {
      console.log("Stored User: " + user);
      const storedUser:IUser = JSON.parse(user);

      if (storedUser.authorization) {
        this.setUser(storedUser);
      } else {
        this.setUser(null);
      }
    } else {
      this.setUser(null);
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (this.state.loggedIn === "LOGGED_IN" && this.state.loggedIn !== prevState.loggedIn) {
      this.getReadArticles();
      this.getDoneExercises();
    }
  }

  public render() {
    const context:IContext = {
      loggedIn: this.state.loggedIn,
      user: this.state.user,
      readArticles: this.state.readArticles,
      completedExercises: this.state.completedExercises,
      lang: this.state.lang,
      statusMsg:this.state.statusMsg,
      statusDisplay:this.state.statusDisplay,
      initiated:this.state.initiated,
      setLang:(data) =>{this.setLang(data);},
      createUser:(data) => {this.createUser(data);},
      login:(data) => {this.login(data);},
      logout:() =>{this.logout();},
      readArticle:(id) => {this.readArticle(id);},
      completedExercise:(exercise) => {this.completedExercise(exercise);},
      loginAnon:(token) => {this.loginAnon(token);},
      testToken:(token) => {this.testToken(token);},
      forgotPassword:(data:{email:string}) => {this.forgotPassword(data);}
    }
    
    return (
      <LoginContext.Provider value={context}>
        {this.props.children}
      </LoginContext.Provider>
    );
  
  }

  private setLang(lang:"SE"|"EN") {
      tn.setLanguage(lang);
      this.setState({lang});
  }

  private setMessage(message:string) {
    this.setState({statusMsg:message, statusDisplay:true});
    clearTimeout(this.timer);
      this.timer =  setTimeout(() => {
        this.setState({statusDisplay:false});
      }, 6000);
  }

  private setUser(user:IUser|null) {
    if (user !== null) {
      localStorage.setItem("user", JSON.stringify(user));
      this.setState({user:user, loggedIn:"LOGGED_IN"});
    } else {
      localStorage.removeItem("user");
      this.setState({user:null, loggedIn:"NOT_LOGGED_IN"});
    }
  }

  private async forgotPassword(data:{email:string}) {
    console.log("resetting password");

    try {
      const res = await httpClient.post(API_URL + "reset", data);
      if (res.status !== null && res.status !== 200) {
        this.setMessage(tn.lang.resetPwSuccess);
      } else {
        this.setMessage(tn.lang.resetPwFail);
      }
    } catch (error) {
      this.setMessage(tn.lang.resetPwFail);
    }
  }

  private async testToken(token:string) {
    
    console.log("testing token");

    try {
      const res = await httpClient.post(API_URL + "prepaid", {token});
      if (res.data !== null && res.data !== undefined) {

        let id:number|undefined = 
          (res.data.tokenA === token)
            ?res.data.parentAId
            :(res.data.tokenB === token)
              ?res.data.parentBId
              :undefined;
        if (id !== undefined) {this.setMessage(tn.lang.loginCodeUsed);}
        console.log(JSON.stringify(res.data));
      } else {
        this.setMessage(tn.lang.loginCodeFail);
      }
      
    } catch (error) {
      this.setMessage(tn.lang.loginCodeFail);
    }
  }

  private async loginAnon(token:string) {
    
    this.setState({loggedIn:"IN_PROGRESS"});
    console.log("logging in anonys");

    try {
      const res = await httpClient.post(API_URL + "prepaid", {token});
      if (res.data !== null && res.data !== undefined) {

        let id:number = (res.data.tokenA === token)
          ?res.data.parentAId
          :(res.data.tokenB === token)
            ?res.data.parentBId
            :-1;
        if (id === 0) {id = -1;}

        const user:IUser = {
          id,
          email: "anon@anon.com",
          firstName: "Anonym",
          lastName: "",
          avatar: 0,
          sex: "UNKNOWN",
          authorization: token,
          country: "SE",
          region: res.data.benefactor,
        }

        this.setUser(user);
      } else {
        this.setUser(null);
        this.setMessage(tn.lang.loginCodeFail);
      }
      
    } catch (error) {
      this.setUser(null);
      this.setMessage(tn.lang.loginCodeFail);
    }
  }

  private async login(login:IUserLogin) {

    this.setState({loggedIn:"IN_PROGRESS"});
    console.log("logging in");

    try {
      const res = await httpClient.post(API_URL + "authentication", login);
      this.setUser(res.data);
    } catch (error) {
      this.setUser(null);
      this.setMessage(tn.lang.loginFail);
    }
  }

  private logout() {
    this.setUser(null);
  }

  private async getReadArticles() {
    console.log("getting articles");
    if (this.state.user) {
      try {
        const res = await httpClient.post(API_URL + "readarticle",{prepaidToken:this.state.user.authorization});
        this.setState({readArticles:res.data, initiated:true});
        console.log("articles updated");
      } catch (error) {
        console.log(error);
        this.setMessage(tn.lang.loadFail);
      }
    } else {
      console.log("no user");
    }
  }

  private async getDoneExercises() {
    console.log("getting done exercises");
    if (this.state.user) {
      try {
        const res = await httpClient.post(API_URL + "doneexercise",{prepaidToken:this.state.user.authorization});
        this.setState({completedExercises:res.data});
        console.log("done exercises updated");
      } catch (error) {
        console.log(error);
        this.setMessage(tn.lang.loadFail);
      }
    } else {
      console.log("no user");
    }
  }

  private async createUser(data: IUserCreate) {
    this.setState({loggedIn:"IN_PROGRESS"});
    httpClient
      .post(API_URL + "account", data)
      .then(response => {
        this.setUser(response.data);
      })
      .catch((reason:any) => {
        this.setUser(null);
        console.log(reason);
        if (reason.response) {
          this.setMessage(tn.lang.createFail+reason.response);
        } else {
          this.setMessage(tn.lang.createFail+reason);
        }
        
      });
  }

  private async readArticle(articleId:number) {
    console.log("marking article as read");
    if (this.state.user) {
      try {
        const newReadList = this.state.readArticles.slice();
        if (newReadList.indexOf(articleId) === -1) {
          await httpClient.post(API_URL + "readarticle",{prepaidToken:this.state.user.authorization, articleId});
          newReadList.unshift(articleId);
          this.setState({readArticles:newReadList});
        }
        console.log("articles updated");
      } catch (error) {
        console.log(error);
        this.setMessage(tn.lang.loadFail);
      }
    } else {
      console.log("no user");
    }
  }

  private async completedExercise(exercise:ICompletedExercise) {
    console.log("marking exercise as done");
    if (this.state.user && exercise.slug.length > 2 && exercise.response.length > 2 ) {
      try {
        const newcompletedList:ICompletedExercise[] = []
        for (const ex of this.state.completedExercises) {
          if (ex.slug !== exercise.slug) {
            newcompletedList.push(ex);
          }
        }
        newcompletedList.unshift(exercise);
        this.setState({completedExercises:newcompletedList});
        if (this.state.user) {
          try {
            await httpClient.post(API_URL + "doneexercise",{...exercise, prepaidToken:this.state.user.authorization});
          } catch (error) {
            console.log(error);
            this.setMessage(tn.lang.loadFail);
          }
        }
        console.log("completed exercise");
      } catch (error) {
        console.log(error);
        this.setMessage(tn.lang.loadFail);
      }
    } else {
      console.log("no user");
    }
  }
}

export default LoginProvider