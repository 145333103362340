import React from "react"
import { RouteComponentProps, withRouter, Link } from "react-router-dom"

import "components/articles/articleStyles/articlepage.css"

import Translate from "service/Translate"
import { ICompletedExercise } from "service/user.types"
import { exercies } from "components/exercises/Exercises"
const tn: Translate = Translate.getInstance()

interface IState {
  didMount: boolean
}

interface IProps extends RouteComponentProps {
  reflections: ICompletedExercise[]
}

class ModulePage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      didMount: false,
    }
  }

  public componentDidMount(): void {
    setTimeout(() => this.setState({ didMount: true }), 200)
  }

  public render() {
    return (
      <div
        className="muni-articlepage"
        style={
          this.state.didMount ? { backgroundColor: "rgb(0 0 0 / 33%)" } : {}
        }
      >
        <div
          className="muni-bigarticle-card"
          onClick={(e) => {
            e.stopPropagation()
          }}
          style={this.state.didMount ? { left: "0vw" } : {}}
        >
          <div
            className="muni-bigarticle-close"
            onClick={() => this.close(false)}
          >
            X
          </div>
          <div className="muni-bigarticle-content" id="card-image">
            <h1>{tn.lang.yourReflections}</h1>

            {this.props.reflections.map((reflection) =>
              this.renderReflection(reflection)
            )}

            <div style={{ minHeight: "30px" }} />
          </div>
        </div>
      </div>
    )
  }

  private renderReflection(reflection: ICompletedExercise) {
    const exercise = exercies.find((ex) => ex.slug === reflection.slug)
    let title = ""
    let imageUrl = ""
    let url = "/panel"

    if (exercise) {
      title = tn.getLanguage() === "SE" ? exercise.title : exercise.title_en
      imageUrl = exercise.imageUrl
      url = "/panel/exercise/" + exercise.slug
    }

    return (
      <>
        <Link
          key={reflection.slug + "tit"}
          to={url}
          style={{ textDecoration: "none" }}
        >
          <div
            className="muni-bigarticle-special-image muni-exercise-title-block"
            style={{ backgroundImage: "url(" + imageUrl + ")" }}
          >
            {title}
            <img
              style={{ width: "28px", height: "auto", marginLeft: "8px" }}
              src={require("../images/link.png")}
              alt="Icon"
            />
          </div>
        </Link>
        <div
          key={reflection.slug}
          className="muni-reflections"
          dangerouslySetInnerHTML={{ __html: reflection.response }}
        />
      </>
    )
  }

  private close(done: boolean) {
    const { history } = this.props
    this.setState({ didMount: false })
    setTimeout(() => {
      history.push("/panel")
    }, 200)
  }
}

export default withRouter(ModulePage)
