import React from "react"
import { useLoginContext } from "./useLoginContext"
import Translate from "service/Translate"

const UserInfoLoggedIn = () => {
  const tn: Translate = Translate.getInstance()
  const context = useLoginContext()
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
    >
      { /*
        <div>
          <div className="muni-grouptitle">
            {context.user?.firstName + " " + context.user?.lastName}
          </div>
          <div className="muni-grouptitle">{context.user?.region}</div>
        </div>
      */}
      <div style={{ marginLeft: "30px" }}>
        <div
          style={{
            marginRight: "20px",
          }}
          onClick={() => context.logout()}
          className="muni-logout-button"
        >
          <img
            src={require("../images/logout.png")}
            alt="copy"
            style={{ width: "18px", height: "auto", opacity: 0.7 }}
          />
          {tn.lang.logout}
        </div>
      </div>
    </div>
  )
}
export default UserInfoLoggedIn
