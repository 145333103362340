// tslint:disable:max-line-length

import * as React from "react";

const terms = <>
          
        <h3>We care about your integrity ❤️!</h3>



<h3>1. General</h3>



<p>We at Happy Parents AB (“Happy Parents”, “Varannan Vecka” or “we”) are very happy for your interest in using our service Varannan Vecka (the “Service”). Data protection and the security of your data is of the highest priority for us. This privacy and cookie policy explains which data we collect about you and how it is processed and shared, which measures we have taken to keep your data secure and how we use cookies. We also describe what rights you have regarding your personal data.</p>



<p>When you use or register on varannanvecka.app, including its associated sites, (jointly the “Website”), or otherwise interact with us, for example through the Service, you acknowledge that you understand that we collect, retain and use of your personal data in accordance with this Privacy Policy.</p>



<p><strong>A users name, e-mail, profile picture, gender identity or age or other personal information will never be shown public to others than to the users &#8221;extended family&#8221; (those invited to the service to collaborate together).</strong></p>



<h3>2. Who we are</h3>



<p>We are Happy Parents AB, company registration no.&nbsp;559197-1717, Alströmmergatan 22, 112 47 Stockholm. You can reach us by telephone at +46 (0)707 61 72 92 or by e-mail at hello@varannanvecka.app. We are the data controller for the processing of your personal data.</p>



<p>We trust that this Privacy Policy answers your questions about our collection, use, protection and disclosure of your personal data. If you have additional questions, please contact us on the address, e-mail address or telephone number provided above.</p>



<h3>3. Amendments</h3>



<p>We may amend this Privacy Policy from time to time. If we do so, the amended Privacy Policy will enter into force when we publish it on the Website. If we make significant changes, we will inform you based on what is appropriate regarding the circumstances, such as through announcement by e-mail or as a notice in our system. The latest date of amendment is stated at the bottom of the Privacy Policy.</p>



<p>If any amendment makes the Privacy Policy less protective of your personal data, such amendment will not apply to data we collected about you before the amendment was made. The amendment will, however, apply to such data if you provide your consent to the new version of the Privacy Policy, or if you provide new information or restate the same information after the entry into force of the amendment.</p>



<h3>4. Collection</h3>



<h4>4.1 What information we collect</h4>



<p>We collect the personal data which has been provided by you or collected on the Website through our use of cookies.&nbsp;The personal data we process consists of the following:</p>



<ul><li>Registration</li></ul>



<p>When you register at Varannan Vecka we ask you to state your email and password, or register using a third party registration such as Facebook or Google, if so we collect the email and name provided by that service. We may verify your registration by a second factor authentication, such as through your mobile telephone number.</p>



<ul><li>User</li></ul>



<p>When you create your profile we ask you for different kind of personal data regarding who you are and your family. We also store the location based on your IP to find out what public holidays and Vacations apply to your child’s school.</p>



<ul><li>Uploaded and data created by you</li></ul>



<p>We collect data from you when you enter or upload data through forms on our website, private chat messages/attachments.</p>



<ul><li>Information from others</li></ul>



<p>Other users may invite you to the system both as a Parent or as a Child or other family member. We collect the information provided including name and e-mail.</p>



<ul><li>Collection of general and statistical data</li></ul>




<ul><li>Logs</li></ul>



<h4>4.2 How we collect your information</h4>



<p>We collect your personal data directly from you in connection with your visit or registration on the Website, or when you use our App.</p>



<p>We may also collect your data, to the extent you have given your consent to it and/or requested it, from our partners or other third parties, for example information that you have provided if registering through Google or Facebook.</p>



<p>We may complement information that you have provided to us with information from other sources, such as information which confirms your identity and profile information. We do this in order to ascertain the correctness of the information we are collecting, and to be able to provide better service.</p>



<p>We log and collect usage data when you visit or otherwise use our Service, including our Website, app and platform technology (e.g., our off-site plugins), such as when you view or click on content or supply information. We use log-ins, cookies, device information and internet protocol addresses to identify you and log your use.</p>



<p>When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select &#8221;Remember Me&#8221;, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</p>



<p>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</p>



<h3>5. Processing purposes</h3>



<p>Our service is a collaboration tool and the purpose of the information gathering is to help you more easily create content that will help you collaborate around your children and their wellbeing.&nbsp;</p>



<p>Please note that certain data can be used even without your consent, for example if we need the data to train AI models. We always try to anonymize personal data if we do. We will never sell your personal data to other parties. We process your personal data for the purposes set out in Clauses&nbsp;5.1-5.4 below.</p>



<h4>5.1 Provide our Services</h4>



<p>If you choose to register with us our main use of data is to use it to help collaborate with other members in your extended family.</p>



<p>We use your personal data as may be needed to provide the Service, e.g. to administer your account, to communicate with you,&nbsp;&nbsp;for billing purposes and to provide support.&nbsp;</p>



<h4>5.2 Improving our Services and data analysis&nbsp;</h4>



<p>We may use your data to improve our services and train our data models. For example training AI to identify abusive language. We always try to anonymize information about before using it to train models and we never release data outside of Happy Parents.</p>



<p>We aggregate and anonymize data (e.g. usage of different schedule set ups) in order to provide insights. This anonymized data may be spread outside of Happy Parents.</p>



<p>We will also process your personal data to produce statistics on how you use our Services. We can do this by, e.g., performing customer satisfaction surveys and market research or by analysing your use of the Website. We use your information to be able to make the Services more user friendly, e.g. by fixing bugs, amending the interface in order for you to easily reach the information that you seek or highlighting popular functions. This processing is based on our interest to continuously improve the Service and provide and develop new services and to perform further anonymised data analysis and use.&nbsp;</p>



<h4>5.3 Prevent Abuse</h4>



<p>&nbsp;Your personal data can also be used to prevent abuse of our Services or to prevent or investigate crimes. Abuse refers to, among other things, fraud, sending junk mail, harassment, attempt of unauthorised login to user accounts and other measures which are in violation of our terms and conditions or law. We can also use your data to examine and defend ourselves against any legal claims from Users or third parties. This processing is based on our interest to avoid abuse of the Website or the Services and to defend ourselves against legal claims.&nbsp;</p>



<h4>5.4 &nbsp;Direct marketing and marketing of the Service&nbsp;</h4>



<p>We use your personal data to send newsletters, offers and relevant information about our Services. The marketing material can be sent to you by mail, SMS or e-mail. You may also receive offers from any of our partners. Such offers are made by us through the Website and not directly by our partners. This processing is based on your prior consent.&nbsp;</p>



<h3>6. How we share your personal data&nbsp;</h3>



<h4>6.1 Varannan Vecka does not share your personal information with any third party except as described below.</h4>



<ul><li>Service providers: Varannan Vecka may use third parties to manage one or more aspects of our business operations, including the processing or handling of personal information. We may share personal information with such third parties to perform services on our behalf such as chat and messaging services, processing payments, sending marketing communications, conducting analysis or research, verifying and validating information that you have provided to us and providing customer support services.</li><li>Sale or transfer of business or assets: Any information we have about you may be transferred or disclosed to a purchaser or prospective purchaser in the event of a sale, assignment, or other transfer of all or a portion of our business or assets. Should such a transfer occur, we will use reasonable efforts to ensure that the transferee uses your information in a manner that is consistent with this Privacy Policy.</li><li>Varannan Vecka may also disclose your information if we are required to do so by law or to comply with legal requests (e.g. disclosure queries, court decisions, legal actions or the like) or when it is necessary to detect, prevent and address fraud and other criminal activity, to protect ourselves, you and other users, including as part of an inquiry if we in good faith believe that such action is required by applicable law. The above may include answering legal requests from non-EU/EEA jurisdictions, where we in good faith believe that the response is required by the law of the relevant jurisdiction, affects users in this jurisdiction and is in accordance with internationally recognized standards. </li></ul>



<h4>6.2 Anonymized and aggregated data may be made available without the Users explicit consent (such as statistics). We may thus publish anonymous and aggregated information about the use of Varannan Vecka or other conclusions that may be deducted from the use of Varannan Vecka.</h4>



<h3>7. How we protect your data</h3>



<ol type="1"><li>The file containing your personal information will be stored on our servers and will only be accessible to our employees and those of our agents and service providers who require it in the course of their duties. We maintain appropriate safeguards and current security standards to protect your personal information against unauthorised access, disclosure, or misuse. For example, electronic records are stored in secure, limited-access servers and electronic data is stored behind secured encryption access. We use technological tools like firewalls and passwords, and we ensure our employees are trained on the importance of maintaining the security and confidentiality of the personal information we process.</li><li>Our Website uses encryption technology, such as Secure Sockets Layer, (“SSL”), to protect your personal information during data transmission. SSL encrypts ordering information such as your name, address, and payment information. Our Website uses an SSL certificate from GoDaddy to confirm that we are in compliance with security protocols in accordance with industry standard.</li><li>When you register as a user and create an online account on our Website, you will be prompted to select a personal password. To maximise your level of protection, you should choose a strong personal password, which means that you should use at least 6 characters. You are solely responsible for maintaining the secrecy of your password and any account information. Varannan Vecka will never send an unsolicited communication asking you for your password or requesting that you update your account credentials.</li></ol>



<h3>8. Storage period</h3>



<ol type="1"><li>We generally retain your personal data as long as it is necessary for the purposes it was collected for or as otherwise required or authorised by applicable law. This inter alia means that as long as our users want to be registered on our website we will store the data.&nbsp;</li><li>It’s up to you to delete your account if you want to and this can be done through the settings of your account. After an account has been deleted we save the data for up to 12 months.</li><li>Aggregated and anonymized data where the user may not be identified may be stored indefinitely.</li><li>Chats and other jointly created items (such as shared schedules) will also be stored in the other persons inbox for as long as their data is not deleted (e.g. the user deletes their account plus another 12 months).</li><li>Logs and statistical data may be stored for up to 36 months.</li></ol>



<h3>9. Your rights</h3>



<ol type="1"><li>To register an account at Varannan Vecka is voluntarily as a User, you always have the possibility to remove or correct the information we have about you. You can do this on your personal page on the Website or in the Service. Please note, however, that we may continue to process your personal data to the extent necessary for the performance of our agreement with you or if there is any other legal basis than consent for processing the data.</li><li>In accordance with applicable personal data legislation, you are entitled to be informed of the personal data we have processed about you, regardless of how the information was collected. If you would like such information, please submit a written request to us to the contact information provided in Clause&nbsp;2.&nbsp;</li><li>If your personal data is processed in breach of applicable personal data legislation, you are entitled to request that your personal data is corrected, blocked or erased. You are also entitled to request that your information is transferred to another service provider (so called data portability).</li><li>Your personal data will not be processed for purposes related to direct marketing or profiling if you oppose such processing. You are, at any time, entitled to withdraw any given consent for the processing of personal data. Such withdrawal may be limited to include only part of the processing, e.g. the part connected to your membership with us or direct marketing. In each newsletter or marketing notification, there is a link included which you can use to unsubscribe.</li><li>If you consider that we have processed your personal data in breach of applicable personal data legislation, you are entitled to lodge a complaint with the Swedish Data Protection Authority (Sw. Datainspektionen), which can be reached at datainspektionen@datainspektionen.se.</li></ol>



<h3>10. Transfer of personal data</h3>



<p>If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>

</>;

export {terms};
