// tslint:disable:max-line-length

import * as React from "react";

const terms_se = <>
          
          <h3>Vi bryr oss om din integritet ❤️!</h3>
<h3>1. Allmänt</h3>
<p>Vi på Happy Parents AB ("Happy Parents", "Varannan Vecka" eller "vi") är väldigt glada för ditt intresse av att använda vår tjänst Varannan Vecka (”Tjänsten”). Dataskydd och säkerheten av din data är av högsta prioritet för oss. Denna integritets- och kakpolicy förklarar vilken data vi samlar in om dig och hur den bearbetas och delas, vilka åtgärder vi har vidtagit för att hålla din data säker och hur vi använder kakor. Vi beskriver också vilka rättigheter du har angående din personliga data.</p>
<p>När du använder eller registrerar dig på varannanvecka.app, inklusive dess associerade webbplatser (gemensamt "Webbplatsen"), eller på annat sätt interagerar med oss, till exempel genom Tjänsten, så erkänner du att du förstår att vi samlar in, behåller och använder din personliga data i enlighet med denna Integritetspolicy.</p>
<p><strong>En användares namn, e-post, profilbild, könsidentitet eller ålder eller annan personlig information kommer aldrig visas offentligt för andra än användarens "utökade familj" (de som har inbjudits till tjänsten för att samarbeta tillsammans).</strong></p>
<h3>2. Vilka vi är</h3>
<p>Vi är Happy Parents AB, organisationsnummer 559197-1717, Alströmmergatan 22, 112 47 Stockholm. Du kan nå oss per telefon på +46 (0)707 61 72 92 eller per e-post på hello@varannanvecka.app. Vi är databearbetningsansvariga för bearbetningen av din personliga data.</p>
<p>Vi tror att denna Integritetspolicy besvarar dina frågor om vår insamling, användning, skydd och offentliggörande av din personliga data. Om du har ytterligare frågor, vänligen kontakta oss på adressen, e-postadressen eller telefonnumret som anges ovan.</p>
<h3>3. Ändringar</h3>

<p>Vi kan ändra denna Integritetspolicy från tid till annan. Om vi gör det träder den ändrade Integritetspolicyn i kraft när vi publicerar den på Webbplatsen. Om vi gör betydande ändringar kommer vi att informera dig beroende på vad som är lämpligt i förhållande till omständigheterna, till exempel genom ett meddelande via e-post eller som en notis i vårt system. Den senaste ändringsdatumet anges längst ner i Integritetspolicyn.</p>
<p>Om någon ändring gör Integritetspolicyn mindre skyddande för din personliga data, så kommer sådan ändring inte att tillämpas på data vi samlat in om dig innan ändringen gjordes. Ändringen kommer dock att tillämpas på sådan data om du ger ditt samtycke till den nya versionen av Integritetspolicyn eller om du lämnar in ny information eller upprepar samma information efter att ändringen trätt i kraft.</p>

<h3>4. Samling</h3>



<h4>4.1 Vilken information vi samlar in</h4>



<p>Vi samlar in de personuppgifter som har tillhandahållits av dig eller som samlats in på webbplatsen genom vår användning av cookies.&nbsp;De personuppgifter vi behandlar består av följande:</p>



<ul><li>Registrering</li></ul>



<p>När du registrerar dig på Varannan Vecka ber vi dig att ange din e-postadress och ditt lösenord, eller registrera dig med en tredjepartsregistrering som Facebook eller Google, i så fall samlar vi in e-postadressen och namnet som tillhandahålls av den tjänsten. Vi kan verifiera din registrering genom en andra faktor autentisering, till exempel via ditt mobiltelefonnummer.</p>



<ul><li>Användare</li></ul>



<p>När du skapar din profil ber vi dig om olika typer av personlig information om vem du är och din familj. Vi lagrar även platsen baserat på din IP för att ta reda på vilka helgdagar och semestrar som gäller för ditt barns skola.</p>



<ul><li>Uppladdad  data och data skapade av dig</li></ul>



<p>Vi samlar in data från dig när du anger eller laddar upp data via formulär på vår webbplats, privata chattmeddelanden/bilagor.</p>



<ul><li>Information från andra</li></ul>



<p>Andra användare kan bjuda in dig till systemet både som förälder eller som barn eller annan familjemedlem. Vi samlar in den information som tillhandahålls inklusive namn och e-post.</p>



<ul><li>Insamling av allmänna och statistiska uppgifter</li></ul>



<ul><li>Loggar</li></ul>



<h4>4.2 Hur vi samlar in din information</h4>



<p>Vi samlar in dina personuppgifter direkt från dig i samband med ditt besök eller registrering på webbplatsen, eller när du använder vår app.</p>



<p>Vi kan också samla in dina uppgifter, i den mån du har gett ditt samtycke till det och/eller begärt det, från våra partners eller andra tredje parter, till exempel information som du har lämnat om du registrerar dig via Google eller Facebook.</ p>



<p>Vi kan komplettera information som du har lämnat till oss med information från andra källor, såsom information som bekräftar din identitet och profilinformation. Vi gör detta för att säkerställa riktigheten av den information vi samlar in och för att kunna ge bättre service.</p>



<p>Vi loggar och samlar in användningsdata när du besöker eller på annat sätt använder vår tjänst, inklusive vår webbplats, app och plattformsteknik (t.ex. våra plugins utanför webbplatsen), till exempel när du visar eller klickar på innehåll eller tillhandahåller information. Vi använder inloggningar, cookies, enhetsinformation och internetprotokolladresser för att identifiera dig och logga din användning.</p>



<p>När du loggar in kommer vi också att skapa flera cookies för att spara din inloggningsinformation och dina skärmvisningsval. Inloggningscookies varar i två dagar och skärmalternativcookies varar i ett år. Om du väljer &#8221;Kom ihåg mig&#8221; kommer din inloggning att fortsätta i två veckor. Om du loggar ut från ditt konto kommer inloggningscookies att tas bort.</p>




<h3>5. Behandlingsändamål</h3>



<p>Vår tjänst är ett samarbetsverktyg och syftet med informationsinsamlingen är att hjälpa dig att lättare skapa innehåll som hjälper dig att samarbeta kring dina barn och deras välmående.&nbsp;</p>



<p>Observera att viss data kan användas även utan ditt samtycke, till exempel om vi behöver data för att träna AI-modeller. Vi försöker alltid att anonymisera personuppgifter om vi gör det. Vi kommer aldrig att sälja dina personuppgifter till andra parter. Vi behandlar dina personuppgifter för de ändamål som anges i klausuler&nbsp;5.1-5.4 nedan.</p>



<h4>5.1 Tillhandahålla våra tjänster</h4>



<p>Om du väljer att registrera dig hos oss är vår huvudsakliga användning av data att använda den för att hjälpa till att samarbeta med andra medlemmar i din utökade familj.</p>



<p>Vi använder dina personuppgifter som kan behövas för att tillhandahålla Tjänsten, t.ex. för att administrera ditt konto, för att kommunicera med dig&nbsp;&nbsp;för faktureringsändamål och för att ge support.&nbsp;</p>


<h4>5.2 Förbättra våra tjänster och dataanalys&nbsp;</h4>



<p>Vi kan använda dina uppgifter för att förbättra våra tjänster och träna våra datamodeller. Till exempel att träna AI för att identifiera kränkande språk. Vi försöker alltid att anonymisera information om innan vi använder den för att träna modeller och vi släpper aldrig data utanför Happy Parents.</p>



<p>Vi aggregerar och anonymiserar data (t.ex. användning av olika schemainställningar) för att ge insikter. Denna anonymiserade information kan spridas utanför Happy Parents.</p>



<p>Vi kommer också att behandla dina personuppgifter för att producera statistik om hur du använder våra tjänster. Vi kan göra detta genom att t.ex. utföra kundnöjdhetsundersökningar och marknadsundersökningar eller genom att analysera din användning av webbplatsen. Vi använder dina uppgifter för att kunna göra Tjänsterna mer användarvänliga, t.ex. genom att fixa buggar, ändra gränssnittet så att du enkelt kan nå den information du söker eller framhäva populära funktioner. Denna bearbetning är baserad på vårt intresse att kontinuerligt förbättra Tjänsten och tillhandahålla och utveckla nya tjänster samt att utföra ytterligare anonymiserad dataanalys och användning.&nbsp;</p>



<h4>5.3 Förhindra missbruk</h4>



<p>&nbsp;Dina personuppgifter kan också användas för att förhindra missbruk av våra tjänster eller för att förhindra eller utreda brott. Missbruk avser bland annat bedrägeri, att skicka skräppost, trakasserier, försök till obehörig inloggning på användarkonton och andra åtgärder som strider mot våra villkor eller lag. Vi kan också använda dina uppgifter för att undersöka och försvara oss mot eventuella rättsliga anspråk från användare eller tredje part. Denna behandling är baserad på vårt intresse att undvika missbruk av webbplatsen eller tjänsterna och att försvara oss mot rättsliga anspråk.&nbsp;</p>



<h4>5.4 &nbsp;Direktmarknadsföring och marknadsföring av tjänsten&nbsp;</h4>



<p>Vi använder dina personuppgifter för att skicka nyhetsbrev, erbjudanden och relevant information om våra tjänster. Marknadsföringsmaterialet kan skickas till dig via post, SMS eller e-post. Du kan också få erbjudanden från någon av våra partners. Sådana erbjudanden görs av oss via webbplatsen och inte direkt av våra partners. Denna bearbetning baseras på ditt förhandsgodkännande.&nbsp;</p>



<h3>6. Hur vi delar dina personuppgifter&nbsp;</h3>



<h4>6.1 Varannan Vecka delar inte din personliga information med någon tredje part förutom enligt beskrivningen nedan.</h4>



<ul><li>Tjänsteleverantörer: Varannan Vecka kan använda tredje part för att hantera en eller flera aspekter av vår affärsverksamhet, inklusive behandling eller hantering av personuppgifter. Vi kan komma att dela personlig information med sådana tredje parter för att utföra tjänster för vår räkning, såsom chatt- och meddelandetjänster, bearbetning av betalningar, skicka marknadskommunikation, utföra analyser eller forskning, verifiera och validera information som du har tillhandahållit oss och tillhandahålla kundsupporttjänster. </li><li>Försäljning eller överföring av verksamhet eller tillgångar: All information vi har om dig kan överföras eller avslöjas till en köpare eller potentiell köpare i händelse av en försäljning, överlåtelse eller annan överföring av hela eller en del av vår verksamhet eller tillgångar. Om en sådan överföring inträffar kommer vi att vidta rimliga ansträngningar för att säkerställa att mottagaren använder din information på ett sätt som är förenligt med denna integritetspolicy.</li><li>Varannan Vecka kan också komma att lämna ut din information om vi är skyldiga att göra det. så enligt lag eller för att följa rättsliga förfrågningar (t.ex. förfrågningar om avslöjande, domstolsbeslut, rättsliga åtgärder eller liknande) eller när det är nödvändigt för att upptäcka, förebygga och ta itu med bedrägeri och annan kriminell aktivitet, för att skydda oss själva, dig och andra användare, inklusive som en del av en förfrågan om vi i god tro anser att en sådan åtgärd krävs enligt tillämplig lag. Ovanstående kan innefatta att besvara juridiska förfrågningar från jurisdiktioner utanför EU/EES, där vi i god tro anser att svaret krävs av lagen i den relevanta jurisdiktionen, påverkar användare i denna jurisdiktion och är i enlighet med internationellt erkända standarder. </li></ul>



<h4>6.2 Anonymiserad och aggregerad data kan göras tillgänglig utan användarnas uttryckliga medgivande (såsom statistik). Vi kan således komma att publicera anonym och aggregerad information om användningen av Varannan Vecka eller andra slutsatser som kan dras från användningen av Varannan Vecka.</h4>



<h3>7. Hur vi skyddar dina uppgifter</h3>



<ol type="1"><li>Filen som innehåller din personliga information kommer att lagras på våra servrar och kommer endast att vara tillgänglig för våra anställda och de av våra agenter och tjänsteleverantörer som behöver det i samband med sina uppgifter. Vi upprätthåller lämpliga skyddsåtgärder och aktuella säkerhetsstandarder för att skydda din personliga information mot obehörig åtkomst, avslöjande eller missbruk. Till exempel lagras elektroniska register i säkra servrar med begränsad åtkomst och elektronisk data lagras bakom säker krypteringsåtkomst. Vi använder tekniska verktyg som brandväggar och lösenord, och vi ser till att våra anställda är utbildade i vikten av att upprätthålla säkerheten ty och konfidentialitet för den personliga information vi behandlar.</li><li>Vår webbplats använder krypteringsteknik, såsom Secure Sockets Layer, ("SSL") för att skydda din personliga information under dataöverföring. SSL krypterar beställningsinformation som ditt namn, adress och betalningsinformation. Vår webbplats använder ett SSL-certifikat från GoDaddy för att bekräfta att vi överensstämmer med säkerhetsprotokoll i enlighet med branschstandard.</li><li>När du registrerar dig som användare och skapar ett onlinekonto på vår webbplats kommer du att bli tillfrågad för att välja ett personligt lösenord. För att maximera din skyddsnivå bör du välja ett starkt personligt lösenord, vilket innebär att du bör använda minst 6 tecken. Du är ensam ansvarig för att upprätthålla sekretessen för ditt lösenord och all kontoinformation. Varannan Vecka kommer aldrig att skicka en oönskad kommunikation som ber dig om ditt lösenord eller begär att du uppdaterar dina kontouppgifter.</li></ol>



<h3>8. Lagringstid</h3>



<ol type="1"><li>Vi behåller i allmänhet dina personuppgifter så länge det är nödvändigt för de syften som de samlades in för eller som på annat sätt krävs eller godkänts av tillämplig lag. Detta innebär bland annat att så länge våra användare vill vara registrerade på vår webbplats kommer vi att lagra uppgifterna.&nbsp;</li><li>Det är upp till dig att radera ditt konto om du vill och detta kan göras genom inställningarna för ditt konto. Efter att ett konto har raderats sparar vi uppgifterna i upp till 12 månader.</li><li>Aggregerad och anonymiserad data där användaren kanske inte kan identifieras kan lagras på obestämd tid.</li><li>Chattar och annat gemensamt skapade objekt (som delade scheman) kommer också att lagras i andra personers inkorg så länge deras data inte raderas (t.ex. användaren raderar sitt konto plus ytterligare 12 månader).</li><li>Loggar och statistiska data kan lagras i upp till 36 månader.</li></ol>



<h3>9. Dina rättigheter</h3>



<ol type="1"><li>Att registrera ett konto hos Varannan Vecka är frivilligt som Användare, du har alltid möjlighet att ta bort eller rätta den information vi har om dig. Du kan göra detta på din personliga sida på Webbplatsen eller i Tjänsten. Observera dock att vi kan fortsätta att behandla dina personuppgifter i den utsträckning det är nödvändigt för fullgörandet av vårt avtal med dig eller om det finns någon annan rättslig grund än samtycke för behandling av uppgifterna.</li><li>I enlighet med med tillämplig personuppgiftslagstiftning har du rätt att få information om vilka personuppgifter vi har behandlat om dig, oavsett hur informationen samlats in. Om du vill ha sådan information, vänligen skicka en skriftlig begäran till oss till kontaktuppgifterna som anges i klausul&nbsp;2.&nbsp;</li><li>Om dina personuppgifter behandlas i strid med tillämplig personuppgiftslagstiftning har du rätt att begära att dina personuppgifter rättas, blockeras eller raderas. Du har också rätt att begära att din information överförs till en annan tjänsteleverantör (så kallad dataportabilitet).</li><li>Dina personuppgifter kommer inte att behandlas för ändamål som är relaterade till direktmarknadsföring eller profilering om du motsätter dig sådan behandling. Du har när som helst rätt att återkalla ett givet samtycke för behandling av personuppgifter. Sådant återkallande kan begränsas till att endast omfatta en del av behandlingen, t.ex. den del som är kopplad till ditt medlemskap hos oss eller direktmarknadsföring. I varje nyhetsbrev eller marknadsföringsmeddelande finns en länk som du kan använda för att avregistrera dig.</li><li>Om du anser att vi har behandlat dina personuppgifter i strid med gällande personuppgiftslagstiftning har du rätt att lämna in en klagomål till Datainspektionen, som nås på datainspektionen@datainspektionen.se.</li></ol>



<h3>10. Överföring av personuppgifter</h3>



<p>Om du har ett konto på den här webbplatsen, eller har lämnat kommentarer, kan du begära att få en exporterad fil med de personuppgifter vi har om dig, inklusive all data du har lämnat till oss. Du kan också begära att vi raderar alla personuppgifter vi har om dig. Detta inkluderar inte några uppgifter som vi är skyldiga att behålla för administrativa, juridiska eller säkerhetsändamål.</p>
</>;



export {terms_se};
