import React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { IArticle } from "service/article.types"
import "components/articles/articleStyles/articlepage.css"
import { exercies } from "components/exercises/Exercises"
import Translate from "service/Translate"
const tn: Translate = Translate.getInstance()

interface IState {
  didMount: boolean
  readFinished: boolean
}

interface IProps extends RouteComponentProps {
  articles: IArticle[]
  readArticles: number[]
  articleRead: (slug: string) => void
  reCalculate: () => void
}

interface IRouteParams {
  slug?: string
}

class ModulePage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      didMount: false,
      readFinished: false,
    }
  }

  public componentDidMount(): void {
    setTimeout(() => this.setState({ didMount: true }), 200)
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    const { slug } = this.props.match.params as IRouteParams
    const oldSlug = prevProps.match.params["slug"]
    if (
      this.state.didMount === false &&
      oldSlug !== undefined &&
      slug !== undefined &&
      oldSlug !== slug
    ) {
      this.setState({ didMount: true, readFinished: false })
      const elmnt = document.getElementById("card-image")
      if (elmnt) {
        elmnt.scrollTo(0, 0)
      }
    }
  }

  public render() {
    const { slug } = this.props.match.params as IRouteParams
    const article = this.props.articles.find((art) => art.slug === slug)
    const content = this.fixContent(article?.content)
    const { history } = this.props

    const alreadyRead =
      article &&
      this.props.readArticles &&
      this.props.readArticles.indexOf(article.id) > -1
    return (
      <div
        className="muni-articlepage"
        style={
          this.state.didMount ? { backgroundColor: "rgb(0 0 0 / 33%)" } : {}
        }
      >
        <div
          className="muni-bigarticle-card"
          onClick={(e) => {
            e.stopPropagation()
          }}
          style={this.state.didMount ? { left: "0vw" } : {}}
        >
          <div
            className="muni-bigarticle-close"
            onClick={() => this.close(false)}
          >
            X
          </div>
          <div
            className="muni-bigarticle-content"
            id="card-image"
            onScroll={(e) => {
              this.onScroll(e)
            }}
          >
            {article ? (
              <>
                <div
                  className="muni-bigarticle-special-image"
                  style={{ backgroundImage: "url(" + article?.imageUrl + ")" }}
                />
                <h1 dangerouslySetInnerHTML={{ __html: article.title }} />
                <div
                  dangerouslySetInnerHTML={{ __html: content }}
                  onClick={(e: any) => {
                    if (
                      e.target.href &&
                      e.target.href.indexOf("www.varannanvecka.app/artikel") >
                        -1
                    ) {
                      e.preventDefault()
                      const target = e.target.href.replace(
                        new RegExp(
                          "https://www.varannanvecka.app/artikel/",
                          "g"
                        ),
                        "/panel/article/"
                      )
                      this.setState({ didMount: false })
                      setTimeout(() => {
                        history.push(target)
                      }, 200)
                    }
                  }}
                />
                {this.state.readFinished || alreadyRead ? (
                  <div
                    className="muni-bigarticle-done"
                    onClick={() => this.close(true)}
                  >
                    <svg
                      className="muni-checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        className="muni-checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className="muni-checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="muni-bigarticle-done-placeholder" />
                )}
              </>
            ) : (
              <div>Loading</div>
            )}
            <div style={{ minHeight: "30px" }} />
            {slug
              ? exercies
                  .filter((exe) => exe.articles.indexOf(slug) > -1)
                  .map((exer) => (
                    <div
                      key={exer.slug}
                      className="muni-button"
                      onClick={(e: any) => {
                        this.setState({ didMount: false })
                        setTimeout(() => {
                          history.push("/panel/exercise/" + exer.slug)
                        }, 200)
                      }}
                    >
                      <img
                        style={{
                          width: "28px",
                          height: "auto",
                          marginRight: "8px",
                        }}
                        src={require("../images/task.png")}
                        alt="Icon"
                      />
                      {tn.lang.exercise +
                        ": " +
                        (tn.getLanguage() === "SE"
                          ? exer.title
                          : exer.title_en)}
                    </div>
                  ))
              : null}
            <div style={{ minHeight: "70px" }} />
          </div>
        </div>
      </div>
    )
  }

  private fixContent(content: string | undefined): string {
    if (!content) {
      return "could not load article"
    }
    return content
  }

  private onScroll(e: any) {
    if (
      !this.state.readFinished &&
      Math.abs(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) < 40
    ) {
      this.setState({ readFinished: true })
      const { slug } = this.props.match.params as IRouteParams
      if (slug) {
        this.props.articleRead(slug)
      }
    }
  }

  private close(done: boolean) {
    const { history } = this.props
    this.setState({ didMount: false })
    setTimeout(() => {
      this.props.reCalculate()
      history.push("/panel")
    }, 200)
  }
}

export default withRouter(ModulePage)
