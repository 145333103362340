import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"
import FlipCard from './FlipCard';

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:boolean;
  reflectionTwo:boolean;
  reflectionThree:boolean;
  reflectionFour:boolean;
  reflectionFive:boolean;
  reflectionSix:boolean;
}

export const ExerciseDifficultQuestions_title = "De svåra frågorna från barnet";
export const ExerciseDifficultQuestions_title_en = "How to answer the difficult questions";
export const ExerciseDifficultQuestions_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/istockphoto-1402725864-170667a.jpg?fit=510%2C338&ssl=1";

const baselanguage = {
  intro: 'Nedan finns exempel på barns frågor. Klicka på frågan så ser du möjliga sätt att svara barnet.',

  questionOne:'Älskar ni varandra fortfarande?',
  questionTwo: 'Kommer ni att bli tillsammans igen?',
  questionThree:'Vem är det som vill skiljas?',
  questionFour: 'Har någon av er träffat en annan eller varit otrogen?',
  questionFive: 'Varför får jag inte vara med er båda hela tiden?',
  questionSix: 'Kommer hela familjen aldrig mer att vara tillsammans?',

  answerOne:'Nej, inte som ett kärlekspar och det är därför vi ska separera. Men vi älskar dig och ska bli föräldrar som älskar att vara föräldrar tillsammans.',
  answerTwo: 'Nej, vår kärlek till varandra har tagit slut.',
  answerThree:'Svara ärligt om det inte var ett gemensamt beslut. Undvik vuxenförklaringar och att anklaga varandra. Även om det är en av er som vill skiljas så finns orsaken till skilsmässan ändå i hur det varit mellan er. Bekräfta om ni är ledsna eller arga just nu, men förklara att de känslorna kommer att lägga sig. Ljug inte, men ge inte heller information som barnet inte har bett om.',
  answerFour: 'Här är balansen mellan ärlighet och överinformation hårfin. Risken är att fokus hamnar på den nya relationen eller otroheten. Om otroheten spelar roll för skilsmässan så behöver den ändå nämnas. Utgå från vad ni tror gagnar barnet att höra, inte vad som känns skönt att berätta. Om den nya var en tillfällig historia eller inte kommer att vara inblandad i barnets liv, väljer ni kanske att styra bort från den frågan. ”Det här handlar om oss två och dig och det är det vi vill prata om nu.” Kanske bekräftar ni att ”ja, mamma har träffat någon, men vår skilsmässa handlar ändå om hur jag och mamma har haft det tillsammans”. Om barn har hört eller anat att någon annan är inblandad så behöver de få det bekräftat.',
  answerFive: 'Därför att mamma och jag inte har det bra när vi bor tillsammans. Vi vill bo på varsitt ställe för att bli gladare och mer avslappnade. Du kommer att få vara tillsammans med oss båda, men inte samtidigt.',
  answerSix: 'Om ni tänker er att ni ska kunna umgås tillsammans, beskriv då hur det ska gå till. Handlar det om gemensamma födelsedagar och avslutningar eller att fika eller äta middag tillsammans ibland? Om ni inte kommer att umgås med varandra så berättar ni det. Kanske säger ni att det blir för stelt att ses gemensamt och därför kommer ni inte att träffas allihop på samma gång. Förhoppningsvis kommer det att kännas lättare längre fram, men det kan ni inte lova.',

}

const engelska: (typeof baselanguage) = {

  intro: 'Nedan finns exempel på barns frågor. Klicka på frågan så ser du möjliga sätt att svara barnet.',

  questionOne: 'Do you still love each other?',
  questionTwo: 'Will you get back together again?',
  questionThree: 'Who wants to get divorced?',
  questionFour: 'Has either of you met someone else or been unfaithful?',
  questionFive: "Why can't I be with both of you all the time?",
  questionSix: 'Will the whole family never be together again?',
  
  answerOne: "No, not as a romantic couple and that is why we are separating. But we love you and will be parents who love being parents together.",
  answerTwo: "No, our love for each other has ended.",
  answerThree: "Answer honestly if it was not a mutual decision. Avoid adult explanations and blaming each other. Even if one of you wants the divorce, the reason for the divorce still lies in how it has been between you. Confirm if you are sad or angry right now, but explain that those feelings will pass. Do not lie, but also do not give information that the child has not asked for.",
  answerFour: "The balance between honesty and over-information is fine here. The risk is that the focus will be on the new relationship or infidelity. If infidelity plays a role in the divorce, it still needs to be mentioned. Base it on what you believe will benefit the child to hear, not what feels comfortable to tell. If the new one was a temporary story or will not be involved in the child's life, you may choose to steer away from the question. 'This is about the two of us and you and that is what we want to talk about now.' Maybe you confirm that 'yes, mom has met someone, but our divorce is still about how you and mom have been together.' If the child has heard or suspected that someone else is involved, they need to have it confirmed.",
  answerFive: "Because mom and I don't feel good when we live together. We want to live in different places to be happier and more relaxed. You will be able to be with both of us, but not at the same time.",
  answerSix: "If you imagine that you will be able to spend time together, describe how it will happen. Is it about common birthdays and endings or having coffee or dinner together sometimes? If you will not spend time together, tell them. Maybe you say that it will be too stiff to see each other together and that is why you will not meet all together at the same time. Hopefully it will feel easier later on, but you can't promise that.",
}

export default function ExerciseDifficultQuestions(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false,
    reflectionOne:false,
    reflectionTwo:false,
    reflectionThree:false,
    reflectionFour:false,
    reflectionFive:false,
    reflectionSix:false,
  });
  const handleChange = (name:string, value:boolean) => {
    setState({ ...state, [name]: value });
  };


const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <p style={{marginTop: '50px'}}>{lang.intro}</p>
      <div className="muni-exercise-flip-box">

        <FlipCard 
          front={lang.questionOne}
          back={lang.answerOne}
          hideImage={true}
          cardStyle={{height:'320px', width:'300px'}}
          showSide={state.reflectionOne?'back':'front'}
          onClick={() =>{handleChange("reflectionOne",!state.reflectionOne);}}
        />
        <FlipCard 
          front={lang.questionTwo}
          back={lang.answerTwo}
          hideImage={true}
          showSide={state.reflectionTwo?'back':'front'}
          onClick={() =>{handleChange("reflectionTwo",!state.reflectionTwo);}}
        />
        <FlipCard 
          front={lang.questionThree}
          back={lang.answerThree}
          hideImage={true}
          cardStyle={{height:'320px', width:'300px'}}
          showSide={state.reflectionThree?'back':'front'}
          onClick={() =>{handleChange("reflectionThree",!state.reflectionThree);}}
        />
        <FlipCard 
          front={lang.questionFive}
          back={lang.answerFive}
          hideImage={true}
          cardStyle={{height:'320px', width:'300px'}}
          showSide={state.reflectionFive?'back':'front'}
          onClick={() =>{handleChange("reflectionFive",!state.reflectionFive);}}
        />
        <FlipCard 
          front={lang.questionFour}
          back={lang.answerFour}
          hideImage={true}
          cardStyle={{height:'440px', width:'360px'}}
          showSide={state.reflectionFour?'back':'front'}
          onClick={() =>{handleChange("reflectionFour",!state.reflectionFour);}}
        />
        <FlipCard 
          front={lang.questionSix}
          back={lang.answerSix}
          hideImage={true}
          cardStyle={{height:'380px', width:'300px'}}
          showSide={state.reflectionSix?'back':'front'}
          onClick={() =>{handleChange("reflectionSix",!state.reflectionSix);}}
        />
      </div>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      <div>{lang.answerOne}</div>
      <h2>{lang.questionTwo}</h2>
      <div>{lang.answerTwo}</div>
      <h2>{lang.questionThree}</h2>
      <div>{lang.answerThree}</div>
      <h2>{lang.questionFour}</h2>
      <div>{lang.answerFour}</div>
      <h2>{lang.questionFive}</h2>
      <div>{lang.answerFive}</div>
      <h2>{lang.questionSix}</h2>
      <div>{lang.answerSix}</div>
    </>);

  return renderToStaticMarkup(reply);
}