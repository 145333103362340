import React from "react"

import "components/articles/articleStyles/category.css"

interface IProps {
  category: string
  imageURL: string
  selected: boolean
  selectCategory: () => void
}

class MainCategory extends React.Component<IProps> {
  public render() {
    return (
      <div
        className="muni-category-button"
        onClick={() => this.props.selectCategory()}
      >
        <img
          style={{ width: "28px", height: "auto", marginRight: "8px" }}
          src={this.props.imageURL}
          alt="Icon"
        />
        {this.props.category}
      </div>
    )
  }
}

export default MainCategory
