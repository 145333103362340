import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:string,
  reflectionTwo:string,
  reflectionThree:string,
}

export const ExerciseDifferences_title = "Skillnader i föräldrastil?";
export const ExerciseDifferences_title_en = "Differences in parenting style?";
export const ExerciseDifferences_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/skor.jpg?fit=509%2C339&ssl=1";

const baselanguage = {
  questionOne: 'Är det några rutiner eller regler som ni tycker olika om och du stör dig på?',
  questionTwo: 'Om de inte ändras på, vilka konsekvenser får det för ditt barn?',
  questionThree: 'Om en vän till dig hade de rutinerna eller reglerna för sitt barn, skulle du säga till då? På vilket sätt i sådana fall?',
}

const engelska: (typeof baselanguage) = {
  questionOne: 'Are there any routines or rules that you disagree with your coparent?',
  questionTwo: 'If they are not changed, what consequences will it have for your child?',
  questionThree: 'If a friend of yours had those routines or rules for their child, would you tell them? In what way in that case?'
}

export default function ExerciseDifferences(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false,
    reflectionOne:"",
    reflectionTwo:"",
    reflectionThree:"",
  });
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <h2>{lang.questionOne}</h2>
      <textarea rows={5} name="reflectionOne" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionOne} onChange={(e) => handleChangeTA(e)}></textarea>
      <h2>{lang.questionTwo}</h2>
      <textarea rows={5} name="reflectionTwo" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionTwo} onChange={(e) => handleChangeTA(e)}></textarea>
      <h2>{lang.questionThree}</h2>
      <textarea rows={5} name="reflectionThree" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionThree} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      {data.reflectionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionOne}</div>):null}
      <h2>{lang.questionTwo}</h2>
      {data.reflectionTwo?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionTwo}</div>):null}
      <h2>{lang.questionThree}</h2>
      {data.reflectionThree?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionThree}</div>):null}
    </>);

  return renderToStaticMarkup(reply);
}