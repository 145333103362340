import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:1|2|3|4|5|undefined;
  reflectionTwo:1|2|3|4|5|undefined;
  reflectionThree:1|2|3|4|5|undefined;
  reflectionFour:1|2|3|4|5|undefined;
  reflectionFive:1|2|3|4|5|undefined;
  reflectionSix:1|2|3|4|5|undefined;
}

export const ExerciseControl_title = "Behöver du kliva fram eller släppa kontrollen?";
export const ExerciseControl_title_en = "Do you need to let go or take control?";
export const ExerciseControl_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/istockphoto-1175239665-170667a.jpg?fit=509%2C339&ssl=1";

const baselanguage = {
  intro: 'Syftet är att väcka tankar och funderingar kring hur det varit tidigare och om förändringar behövs. ',
  questionOne: 'Den som tagit ut Föräldraledigheten är:',
  questionTwo: 'Den som har tagit det praktiska ansvaret för barnet så här långt är:',
  questionThree: 'Jag känner mig trygg i mitt föräldraskap.',
  questionFour: 'Jag känner mig trygg med medförälderns föräldraskap.',
  questionFive: 'Jag upplever medföräldern som “bossig” och kontrollerande kring mitt föräldraskap.',
  questionSix: 'Jag känner att vi föräldrar kan dela erfarenheter och kunskaper kring barnet.',
  outro : "Titta på dina svar! Om du har en stor övervikt åt ett eller annat håll indikerar det att du/ni behöver fundera över era roller och ansvarsfördelning kring barnet.",
  outroTwo : "Läs gärna artikeln igen nu när du gjort testet och se om det väcker fler tankar hos dig. ",


  me: 'Jag',
  mostlyMe: 'Mestadels jag',
  equal: 'Båda',
  mostlyCoparent: 'Mestadels medförälder',
  coparent: 'Medföräldern',
  agree:'Håller med',
  agreeOne:'Håller inte alls med',
  agreeTwo:'Håller med lite grann',
  agreeThree:'Håller med till viss del',
  agreeFour:'Håller mestadels med',
  agreeFive:'Håller inte alls med',
  disagree:'Håller inte med',
  noAnswer:"Inget svar",
  yes: 'Ja',
  no: 'Nej',
}

const engelska: (typeof baselanguage) = {
  intro: 'The purpose is to reflect about how things used to be and whether changes are needed. ',
  questionOne: 'The person who used the Parental Leave is:',
  questionTwo: 'The person who has taken practical responsibility for the child so far is:',
  questionThree: 'I feel secure in my parenting skills.',
  questionFour: "I feel safe with the co-parent's parenting skills.",
  questionFive: 'I feel that the coparent is “bossy” and controling about my parenting.',
  questionSix: 'I feel that we, as parent, can share experiences and knowledge about the child.',
  me: 'Me',
  mostlyMe: 'Mostly me',
  equal: 'Both',
  mostlyCoparent: 'Mostly coparent',
  coparent: 'The coparent',
  outro : "Take a look at your answers! If you have lean heavily one direction or another in your answers, it indicates that you need to think about your roles and division of responsibilities around the child.",
  outroTwo : "",

  agree:'Agree',
  agreeOne:'Strongly disagree',
  agreeTwo:'Agree a little',
  agreeThree:'Agree to some extent',
  agreeFour:'Mostly agree',
  agreeFive:'Strongly disagree',
  disagree:'Disagree',
  noAnswer:"No answer",
  yes: 'Yes',
  no: 'No',
}

export default function ExerciseControl(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false, 
    reflectionOne:undefined,
    reflectionTwo:undefined,
    reflectionThree:undefined,
    reflectionFour:undefined,
    reflectionFive:undefined,
    reflectionSix:undefined,
  });
  const handleChange = (name:string, value:1|2|3|4|5) => {
    setState({ ...state, [name]: value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <div>{lang.intro}</div>

      <h2>{lang.questionOne}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionOne", 1)} >{lang.me}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===1)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", 1)}>{1}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===2)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", 2)}>{2}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===3)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", 3)}>{3}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===4)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", 4)}>{4}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===5)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", 5)}>{5}</div>
        <div style={{alignSelf: 'center'}} onClick={() =>handleChange("reflectionOne", 5)}>{lang.coparent}</div>
      </div>

      <h2>{lang.questionTwo}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionTwo", 1)} >{lang.me}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===1)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", 1)}>{1}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===2)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", 2)}>{2}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===3)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", 3)}>{3}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===4)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", 4)}>{4}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===5)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", 5)}>{5}</div>
        <div style={{alignSelf: 'center'}} onClick={() =>handleChange("reflectionTwo", 5)}>{lang.coparent}</div>
      </div>

      <h2>{lang.questionThree}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionThree", 1)} >{lang.agree}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===1)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", 1)}>{1}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===2)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", 2)}>{2}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===3)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", 3)}>{3}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===4)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", 4)}>{4}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===5)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", 5)}>{5}</div>
        <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionThree", 5)} >{lang.disagree}</div>
      </div>

      <h2>{lang.questionFour}</h2>
      <div className="muni-exercise-clickanswer-box">
      <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionFour", 1)} >{lang.disagree}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===1)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", 1)}>{1}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===2)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", 2)}>{2}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===3)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", 3)}>{3}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===4)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", 4)}>{4}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===5)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", 5)}>{5}</div>
        <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionFour", 5)} >{lang.agree}</div>
      </div>

      <h2>{lang.questionFive}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionFive", 1)} >{lang.disagree}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFive===1)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFive", 1)}>{1}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFive===2)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFive", 2)}>{2}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFive===3)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFive", 3)}>{3}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFive===4)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFive", 4)}>{4}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFive===5)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFive", 5)}>{5}</div>
        <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionFive", 5)} >{lang.agree}</div>
      </div>

      <h2>{lang.questionSix}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div className={("muni-exercise-clickanswer"+((state.reflectionSix===1)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionSix", 1)}>{lang.yes}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionSix===2)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionSix", 2)}>{lang.no}</div>
      </div>

      <p style={{marginTop:"50px"}}>{lang.outro}</p>
      <p>{lang.outroTwo}</p>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      {((data.reflectionOne===1)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.me}</div>:null)}
      {((data.reflectionOne===2)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.mostlyMe}</div>:null)}
      {((data.reflectionOne===3)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.equal}</div>:null)}
      {((data.reflectionOne===4)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.mostlyCoparent}</div>:null)}
      {((data.reflectionOne===5)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.coparent}</div>:null)}
      {((data.reflectionOne===undefined)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.noAnswer}</div>:null)}

      <h2>{lang.questionTwo}</h2>
      {((data.reflectionTwo===1)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.me}</div>:null)}
      {((data.reflectionTwo===2)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.mostlyMe}</div>:null)}
      {((data.reflectionTwo===3)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.equal}</div>:null)}
      {((data.reflectionTwo===4)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.mostlyCoparent}</div>:null)}
      {((data.reflectionTwo===5)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.coparent}</div>:null)}
      {((data.reflectionTwo===undefined)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.noAnswer}</div>:null)}

      <h2>{lang.questionThree}</h2>
      {((data.reflectionThree===1)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeOne}</div>:null)}
      {((data.reflectionThree===2)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeTwo}</div>:null)}
      {((data.reflectionThree===3)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeThree}</div>:null)}
      {((data.reflectionThree===4)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeFour}</div>:null)}
      {((data.reflectionThree===5)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeFive}</div>:null)}
      {((data.reflectionThree===undefined)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.noAnswer}</div>:null)}

      <h2>{lang.questionFour}</h2>
      {((data.reflectionFour===5)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeOne}</div>:null)}
      {((data.reflectionFour===4)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeTwo}</div>:null)}
      {((data.reflectionFour===3)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeThree}</div>:null)}
      {((data.reflectionFour===2)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeFour}</div>:null)}
      {((data.reflectionFour===1)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeFive}</div>:null)}
      {((data.reflectionFour===undefined)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.noAnswer}</div>:null)}

      <h2>{lang.questionFive}</h2>
      {((data.reflectionFive===5)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeOne}</div>:null)}
      {((data.reflectionFive===4)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeTwo}</div>:null)}
      {((data.reflectionFive===3)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeThree}</div>:null)}
      {((data.reflectionFive===2)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeFour}</div>:null)}
      {((data.reflectionFive===1)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.agreeFive}</div>:null)}
      {((data.reflectionFive===undefined)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.noAnswer}</div>:null)}

      <h2>{lang.questionSix}</h2>
      {((data.reflectionSix===1)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.yes}</div>:null)}
      {((data.reflectionSix===2)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.no}</div>:null)}
      {((data.reflectionSix===undefined)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.noAnswer}</div>:null)}

    </>);

  return renderToStaticMarkup(reply);
}