import { useLoginContext } from "components/useLoginContext"
import React, { useState } from "react"
import Translate from "service/Translate"
// import { useLoginContext } from "components/useLoginContext";
const tn: Translate = Translate.getInstance()

interface IProps {
  displayAllOptions: () => void
}

export default function LandingForgotPw(props: IProps) {
  const context = useLoginContext()
  const [state, setState] = useState<{ email: string; isValidEmail: boolean }>({
    email: "",
    isValidEmail: true,
  })

  const testValidEmail = (email: string): boolean => {
    const emailRegex = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/
    return emailRegex.test(email.trim())
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setState({
      ...state,
      email: value,
      isValidEmail: testValidEmail(value),
    })
  }
  return (
    <div className="modal-overlay" onClick={props.displayAllOptions}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="muni-signup-box">
          <form>
            <div className="muni-signup-header">{tn.lang.forgotPassword}:</div>
            <input
              name="email"
              type="text"
              className="muni-input"
              placeholder={tn.lang.email}
              value={state.email}
              onChange={handleChange}
            />
            {!state.isValidEmail && (
              <div className="error">{tn.lang.invalidEmail}</div>
            )}
            <div className={"muni-optionsbox"}>
              <div style={{ textDecoration: "underline", cursor: "pointer" }}>
                <div onClick={props.displayAllOptions}>{tn.lang.back}</div>
              </div>
              <div>
                <button
                  type="button"
                  className="muni-register-button"
                  onClick={() => {
                    context.forgotPassword({ email: state.email })
                  }}
                >
                  {tn.lang.submit}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
