import React from "react";
import './exercises.css'

interface IProps {
  showSide: 'front'|'back';
  front: string;
  back:string;
  hideImage?:boolean;
  cardStyle?:React.CSSProperties;
  onClick:() => void;
}

export default function FlipCard(props:IProps) {
  return (
    <div className={("muni-exercise-flip-card"+((props.showSide==="back")?" muni-exercise-flip-card-flip":""))} 
      style={props.cardStyle?props.cardStyle:undefined} onClick={() => props.onClick()}>
      <div className="muni-exercise-flip-card-inner">
        <div className="muni-exercise-flip-card-front">
          <div className="flipcard-text">{props.front}</div>
        </div>
        <div className="muni-exercise-flip-card-back">
          {props.hideImage?null:<img className="flipcard-image" src={require('../../images/checkmark.png')} alt="Checkmark" />}
          <div className="flipcard-text">{props.back}</div>
        </div>
    </div>
  </div>
  );
}
