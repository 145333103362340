import React from "react"
import "./landingStyles/landingFooter.css"

import Translate from "service/Translate"

const tn: Translate = Translate.getInstance()

export default function landingFooter() {
  const redirectToInstagram = () => {
    window.location.href = "https://www.instagram.com/varannanvecka.app/"
  }

  const redirectToFacebook = () => {
    window.location.href =
      "https://www.facebook.com/varannanveckaapp/?locale=sv_SE"
  }

  const redirectToTiktok = () => {
    window.location.href = "https://www.tiktok.com/discover/Varannan-vecka"
  }
  const redirectToLinkedIn = () => {
    window.location.href = "https://www.linkedin.com/company/varannan-vecka"
  }

    const redirectToAboutUs = () => {
    window.open("https://www.varannanvecka.app/om-oss/")
  }

   const contactUs = () => {
    window.location.href = "mailto:hello@varannanvecka.app"
  }

  return (
    <div className="muni-landingFooter">
      <div className="muni-footer-top">
        <div className="mini-landingFooter-logo-container">
        <img
          src="https://kommun.varannanvecka.app/wp-content/uploads/2021/03/VV-symbol.svg"
          alt="Varannan Vecka logo"
          className="muni-intro-landingIntro-logo"
        />
        <h4>{tn.lang.vvintroAppName}</h4>
        </div>
        <div className="muni-landingFooter-aboutUs">
          <p>{tn.lang.footerAboutUs}</p>
          <div className="muni-landingFooter-socialmedia-Container">
          <img
            onClick={redirectToFacebook}
            src="./facebook.svg"
            alt="facebook logo"
            className="muni-intro-landingIntro-logo"
          />
          <img
            onClick={redirectToInstagram}
            src="./instagram.svg"
            alt="instagram logo"
            className="muni-intro-landingIntro-logo"
          />
          <img
            onClick={redirectToTiktok}
            src="./tiktok.svg"
            alt="tiktok logo"
            className="muni-intro-landingIntro-logo"
          />

          <img
            onClick={redirectToLinkedIn}
            src="./linkedin.svg"
            alt="youtube logo"
            className="muni-intro-landingIntro-logo"
          />
          </div>
        </div>
      </div>
      <span className="muni-footer-line"></span>

      <div className="muni-footer-bottom">
        
        <div className="muni-footer-bottom-contact">
          <p onClick={redirectToAboutUs}>{tn.lang.hamburgerAboutUs}</p>
          <p onClick={contactUs}>{tn.lang.reflektionContactUs}</p>
          <p onClick={contactUs}>{tn.lang.reflektionSupport}</p>
        </div>
      
          <p>@ 2024, All Rights Reserved</p>
        
      </div>
    </div>
  )
}
