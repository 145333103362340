import { useLoginContext } from "components/useLoginContext"
import React, { useState } from "react"
import Translate from "service/Translate"
import { terms } from "./signup.terms"
import { terms_se } from "./signup.terms.se"
import "./landingStyles/landingRegister.css"


const tn: Translate = Translate.getInstance()

interface IProps {
  displayAllOptions: () => void
  hasCode: boolean
  openLoginModal: () => void
}

interface IState {
  hasClicked: boolean
  code: string
  firstName: string
  sirName: string
  email: string
  password: string
  muni: string
  anon: boolean
  gdpr: boolean
  showPrivacyPolicy: boolean
}

export default function LandingRegister(props: IProps) {
  const context = useLoginContext()
  const [state, setState] = React.useState<IState>({
    hasClicked: false,
    code: "Separation",
    firstName: "",
    sirName: "",
    email: "",
    password: "",
    muni: "",
    anon: false,
    gdpr: false,
    showPrivacyPolicy: false,
  })

  const [isValidEmail, setIsValidEmail] = useState<boolean>(true)

  const testValidEmail = (email: string): boolean => {
    const emailRegex = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/
    return emailRegex.test(email.trim())
  }

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setState({ ...state, [name]: value })
    if (name === "email") {
      setIsValidEmail(testValidEmail(value))
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }
  const handleChangeUC = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim().toLocaleUpperCase()
    setState({ ...state, [event.target.name]: value })
  }
  const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  let valid = true
  if (state.firstName.trim().length < 2) {
    valid = false
  }
  if (state.sirName.trim().length < 2) {
    valid = false
  }
  if (state.email.trim().length < 2 || state.email.indexOf("@") === -1) {
    valid = false
  }
  if (state.password.trim().length < 6) {
    valid = false
  }
  if (state.gdpr === false) {
    valid = false
  }

  if (state.anon && state.code.trim().length === 6) {
    valid = true
  }

  return (
    
      <div className="muni-signup-form-conatiner" id="muni-signup-form-conatiner-register">
        <form className="muni-signup-form">       

        <div className="muni-signup-header">{tn.lang.createAcc}</div>

        <div className="muni-signup-inputs">
        {props.hasCode ? (
          <input
            name="code"
            type="text"
            value={state.code}
            onChange={(e) => handleChangeUC(e)}
            className="muni-input"
            placeholder={tn.lang.codeFromMun}
            onBlur={() => {
              if (state.code.length > 2) {
                context.testToken(state.code)
              }
            }}
          />
        ) : null}
        {props.hasCode ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <input
              name="anon"
              className="muni-checkbox"
              type="checkbox"
              checked={state.anon}
              onChange={(e) => setState({ ...state, anon: !state.anon })}
            />
            <label htmlFor="anon">{tn.lang.iWantAnon}</label>
          </div>
        ) : null}
        {!state.anon ? (
          <input
            name="firstName"
            type="text"
            value={state.firstName}
            onChange={(e) => handleChange(e)}
            className="muni-input"
            placeholder={tn.lang.firstName}
          />
        ) : null}
        {!state.anon ? (
          <input
            name="sirName"
            type="text"
            value={state.sirName}
            onChange={(e) => handleChange(e)}
            className="muni-input"
            placeholder={tn.lang.lastName}
          />
        ) : null}
        {!state.anon ? (
          <>
            <input
              name="email"
              type="text"
              value={state.email}
              onChange={handleEmail}
              className="muni-input"
              placeholder={tn.lang.email}
            />{" "}
            {!isValidEmail && (
              <label className="error">{tn.lang.invalidEmail}</label>
            )}
          </>
        ) : null}
        {!state.anon ? (
          <input
            name="password"
            type="password"
            value={state.password}
            onChange={(e) => handleChange(e)}
            className="muni-input"
            placeholder={tn.lang.password}
          />
        ) : null}

        {state.anon ? <div>{tn.lang.sureAnon}</div> : null}
        {props.hasCode ||
        window.location.hostname.toLowerCase().indexOf("fj.") > -1 ? null : (
          <>
            {/*<span>{tn.lang.pickMuni}</span>  */}
            <select
              name="muni"
              value={state.muni}
              onChange={(e) => handleChangeSelect(e)}
              className="muni-input"
            >
              <option value="" disabled>
                Kommun
              </option>
              <option value="Ale">Ale</option>
              <option value="Alingsas">Alingsas</option>
              <option value="Alvesta">Alvesta</option>
              <option value="Aneby">Aneby</option>
              <option value="Arboga">Arboga</option>
              <option value="Arjeplog">Arjeplog</option>
              <option value="Arvidsjaur">Arvidsjaur</option>
              <option value="Arvika">Arvika</option>
              <option value="Askersund">Askersund</option>
              <option value="Avesta">Avesta</option>
              <option value="Bengtsfors">Bengtsfors</option>
              <option value="Berg">Berg</option>
              <option value="Bjurholm">Bjurholm</option>
              <option value="Bjuv">Bjuv</option>
              <option value="Boden">Boden</option>
              <option value="Bollebygd">Bollebygd</option>
              <option value="Bollnas">Bollnas</option>
              <option value="Borgholm">Borgholm</option>
              <option value="Borlange">Borlange</option>
              <option value="Boras">Boras</option>
              <option value="Botkyrka">Botkyrka</option>
              <option value="Boxholm">Boxholm</option>
              <option value="Bromolla">Bromolla</option>
              <option value="Bracke">Bracke</option>
              <option value="Burlov">Burlov</option>
              <option value="Bastad">Bastad</option>
              <option value="Dals-Ed">Dals-Ed</option>
              <option value="Danderyd">Danderyd</option>
              <option value="Degerfors">Degerfors</option>
              <option value="Dorotea">Dorotea</option>
              <option value="Eda">Eda</option>
              <option value="Ekero">Ekero</option>
              <option value="Eksjo">Eksjo</option>
              <option value="Emmaboda">Emmaboda</option>
              <option value="Enkoping">Enkoping</option>
              <option value="Eskilstuna">Eskilstuna</option>
              <option value="Eslov">Eslov</option>
              <option value="Essunga">Essunga</option>
              <option value="Fagersta">Fagersta</option>
              <option value="Falkenberg">Falkenberg</option>
              <option value="Falkoping">Falkoping</option>
              <option value="Falun">Falun</option>
              <option value="Filipstad">Filipstad</option>
              <option value="Finspang">Finspang</option>
              <option value="Flen">Flen</option>
              <option value="Forshaga">Forshaga</option>
              <option value="Fargelanda">Fargelanda</option>
              <option value="Gagnef">Gagnef</option>
              <option value="Gislaved">Gislaved</option>
              <option value="Gnesta">Gnesta</option>
              <option value="Gnosjo">Gnosjo</option>
              <option value="Gotland">Gotland</option>
              <option value="Grums">Grums</option>
              <option value="Grastorp">Grastorp</option>
              <option value="Gullspang">Gullspang</option>
              <option value="Gallivare">Gallivare</option>
              <option value="Gavle">Gavle</option>
              <option value="Goteborg">Goteborg</option>
              <option value="Gotene">Gotene</option>
              <option value="Habo">Habo</option>
              <option value="Hagfors">Hagfors</option>
              <option value="Hallsberg">Hallsberg</option>
              <option value="Hallstahammar">Hallstahammar</option>
              <option value="Halmstad">Halmstad</option>
              <option value="Hammaro">Hammaro</option>
              <option value="Haninge">Haninge</option>
              <option value="Haparanda">Haparanda</option>
              <option value="Heby">Heby</option>
              <option value="Hedemora">Hedemora</option>
              <option value="Helsingborg">Helsingborg</option>
              <option value="Herrljunga">Herrljunga</option>
              <option value="Hjo">Hjo</option>
              <option value="Hofors">Hofors</option>
              <option value="Huddinge">Huddinge</option>
              <option value="Hudiksvall">Hudiksvall</option>
              <option value="Hultsfred">Hultsfred</option>
              <option value="Hylte">Hylte</option>
              <option value="Habo">Habo</option>
              <option value="Hallefors">Hallefors</option>
              <option value="Harjedalen">Harjedalen</option>
              <option value="Harnosand">Harnosand</option>
              <option value="Harryda">Harryda</option>
              <option value="Hassleholm">Hassleholm</option>
              <option value="Hoganas">Hoganas</option>
              <option value="Hogsby">Hogsby</option>
              <option value="Horby">Horby</option>
              <option value="Hoor">Hoor</option>
              <option value="Jokkmokk">Jokkmokk</option>
              <option value="Jarfalla">Jarfalla</option>
              <option value="Jonkoping">Jonkoping</option>
              <option value="Kalix">Kalix</option>
              <option value="Kalmar">Kalmar</option>
              <option value="Karlsborg">Karlsborg</option>
              <option value="Karlshamn">Karlshamn</option>
              <option value="Karlskoga">Karlskoga</option>
              <option value="Karlskrona">Karlskrona</option>
              <option value="Karlstad">Karlstad</option>
              <option value="Katrineholm">Katrineholm</option>
              <option value="Kil">Kil</option>
              <option value="Kinda">Kinda</option>
              <option value="Kiruna">Kiruna</option>
              <option value="Klippan">Klippan</option>
              <option value="Knivsta">Knivsta</option>
              <option value="Kramfors">Kramfors</option>
              <option value="Kristianstad">Kristianstad</option>
              <option value="Kristinehamn">Kristinehamn</option>
              <option value="Krokom">Krokom</option>
              <option value="Kumla">Kumla</option>
              <option value="Kungsbacka">Kungsbacka</option>
              <option value="Kungsor">Kungsor</option>
              <option value="Kungalv">Kungalv</option>
              <option value="Kavlinge">Kavlinge</option>
              <option value="Koping">Koping</option>
              <option value="Laholm">Laholm</option>
              <option value="Landskrona">Landskrona</option>
              <option value="Laxa">Laxa</option>
              <option value="Lekeberg">Lekeberg</option>
              <option value="Leksand">Leksand</option>
              <option value="Lerum">Lerum</option>
              <option value="Lessebo">Lessebo</option>
              <option value="Lidingo">Lidingo</option>
              <option value="Lidkoping">Lidkoping</option>
              <option value="Lilla Edet">Lilla Edet</option>
              <option value="Lindesberg">Lindesberg</option>
              <option value="Linkoping">Linkoping</option>
              <option value="Ljungby">Ljungby</option>
              <option value="Ljusdal">Ljusdal</option>
              <option value="Ljusnarsberg">Ljusnarsberg</option>
              <option value="Lomma">Lomma</option>
              <option value="Ludvika">Ludvika</option>
              <option value="Lulea">Lulea</option>
              <option value="Lund">Lund</option>
              <option value="Lycksele">Lycksele</option>
              <option value="Lysekil">Lysekil</option>
              <option value="Malmo">Malmo</option>
              <option value="Malung-Salen">Malung-Salen</option>
              <option value="Mala">Mala</option>
              <option value="Mariestad">Mariestad</option>
              <option value="Mark">Mark</option>
              <option value="Markaryd">Markaryd</option>
              <option value="Mellerud">Mellerud</option>
              <option value="Mjolby">Mjolby</option>
              <option value="Mora">Mora</option>
              <option value="Motala">Motala</option>
              <option value="Mullsjo">Mullsjo</option>
              <option value="Munkedal">Munkedal</option>
              <option value="Munkfors">Munkfors</option>
              <option value="Molndal">Molndal</option>
              <option value="Monsteras">Monsteras</option>
              <option value="Morbylanga">Morbylanga</option>
              <option value="Nacka">Nacka</option>
              <option value="Nora">Nora</option>
              <option value="Norberg">Norberg</option>
              <option value="Nordanstig">Nordanstig</option>
              <option value="Nordmaling">Nordmaling</option>
              <option value="Norrkoping">Norrkoping</option>
              <option value="Norrtalje">Norrtalje</option>
              <option value="Norsjo">Norsjo</option>
              <option value="Nybro">Nybro</option>
              <option value="Nykvarn">Nykvarn</option>
              <option value="Nykoping">Nykoping</option>
              <option value="Nynashamn">Nynashamn</option>
              <option value="Nassjo">Nassjo</option>
              <option value="Ockelbo">Ockelbo</option>
              <option value="Olofstrom">Olofstrom</option>
              <option value="Orsa">Orsa</option>
              <option value="Orust">Orust</option>
              <option value="Osby">Osby</option>
              <option value="Oskarshamn">Oskarshamn</option>
              <option value="Ovanaker">Ovanaker</option>
              <option value="Oxelosund">Oxelosund</option>
              <option value="Pajala">Pajala</option>
              <option value="Partille">Partille</option>
              <option value="Perstorp">Perstorp</option>
              <option value="Pitea">Pitea</option>
              <option value="Ragunda">Ragunda</option>
              <option value="Robertsfors">Robertsfors</option>
              <option value="Ronneby">Ronneby</option>
              <option value="Rattvik">Rattvik</option>
              <option value="Sala">Sala</option>
              <option value="Salem">Salem</option>
              <option value="Sandviken">Sandviken</option>
              <option value="Sigtuna">Sigtuna</option>
              <option value="Simrishamn">Simrishamn</option>
              <option value="Sjobo">Sjobo</option>
              <option value="Skara">Skara</option>
              <option value="Skelleftea">Skelleftea</option>
              <option value="Skinnskatteberg">Skinnskatteberg</option>
              <option value="Skurup">Skurup</option>
              <option value="Skovde">Skovde</option>
              <option value="Smedjebacken">Smedjebacken</option>
              <option value="Solleftea">Solleftea</option>
              <option value="Sollentuna">Sollentuna</option>
              <option value="Solna">Solna</option>
              <option value="Sorsele">Sorsele</option>
              <option value="Sotenas">Sotenas</option>
              <option value="Staffanstorp">Staffanstorp</option>
              <option value="Stenungsund">Stenungsund</option>
              <option value="Stockholm">Stockholm</option>
              <option value="Storfors">Storfors</option>
              <option value="Storuman">Storuman</option>
              <option value="Strangnas">Strangnas</option>
              <option value="Stromstad">Stromstad</option>
              <option value="Stromsund">Stromsund</option>
              <option value="Sundbyberg">Sundbyberg</option>
              <option value="Sundsvall">Sundsvall</option>
              <option value="Sunne">Sunne</option>
              <option value="Surahammar">Surahammar</option>
              <option value="Svalov">Svalov</option>
              <option value="Svedala">Svedala</option>
              <option value="Svenljunga">Svenljunga</option>
              <option value="Saffle">Saffle</option>
              <option value="Sater">Sater</option>
              <option value="Savsjo">Savsjo</option>
              <option value="Soderhamn">Soderhamn</option>
              <option value="Soderkoping">Soderkoping</option>
              <option value="Sodertalje">Sodertalje</option>
              <option value="Solvesborg">Solvesborg</option>
              <option value="Tanum">Tanum</option>
              <option value="Tibro">Tibro</option>
              <option value="Tidaholm">Tidaholm</option>
              <option value="Tierp">Tierp</option>
              <option value="Timra">Timra</option>
              <option value="Tingsryd">Tingsryd</option>
              <option value="Tjorn">Tjorn</option>
              <option value="Tomelilla">Tomelilla</option>
              <option value="Torsby">Torsby</option>
              <option value="Torsas">Torsas</option>
              <option value="Tranemo">Tranemo</option>
              <option value="Tranas">Tranas</option>
              <option value="Trelleborg">Trelleborg</option>
              <option value="Trollhattan">Trollhattan</option>
              <option value="Trosa">Trosa</option>
              <option value="Tyreso">Tyreso</option>
              <option value="Taby">Taby</option>
              <option value="Toreboda">Toreboda</option>
              <option value="Uddevalla">Uddevalla</option>
              <option value="Ulricehamn">Ulricehamn</option>
              <option value="Umea">Umea</option>
              <option value="Upplands Vasby">Upplands Vasby</option>
              <option value="Upplands-Bro">Upplands-Bro</option>
              <option value="Uppsala">Uppsala</option>
              <option value="Uppvidinge">Uppvidinge</option>
              <option value="Vadstena">Vadstena</option>
              <option value="Vaggeryd">Vaggeryd</option>
              <option value="Valdemarsvik">Valdemarsvik</option>
              <option value="Vallentuna">Vallentuna</option>
              <option value="Vansbro">Vansbro</option>
              <option value="Vara">Vara</option>
              <option value="Varberg">Varberg</option>
              <option value="Vaxholm">Vaxholm</option>
              <option value="Vellinge">Vellinge</option>
              <option value="Vetlanda">Vetlanda</option>
              <option value="Vilhelmina">Vilhelmina</option>
              <option value="Vimmerby">Vimmerby</option>
              <option value="Vindeln">Vindeln</option>
              <option value="Vingaker">Vingaker</option>
              <option value="Vargarda">Vargarda</option>
              <option value="Vanersborg">Vanersborg</option>
              <option value="Vannas">Vannas</option>
              <option value="Varmdo">Varmdo</option>
              <option value="Varnamo">Varnamo</option>
              <option value="Vastervik">Vastervik</option>
              <option value="Vasteras">Vasteras</option>
              <option value="Vaxjo">Vaxjo</option>
              <option value="Ydre">Ydre</option>
              <option value="Ystad">Ystad</option>
              <option value="Amal">Åmal</option>
              <option value="Ange">Ånge</option>
              <option value="Are">Åre</option>
              <option value="Arjang">Årjang</option>
              <option value="Asele">Åsele</option>
              <option value="Astorp">Åstorp</option>
              <option value="Atvidaberg">Åtvidaberg</option>
              <option value="Almhult">Älmhult</option>
              <option value="Alvdalen">Älvdalen</option>
              <option value="Alvkarleby">Älvkarleby</option>
              <option value="Alvsbyn">Älvsbyn</option>
              <option value="Angelholm">Ängelholm</option>
              <option value="Ockero">Öckero</option>
              <option value="Odeshog">Ödeshog</option>
              <option value="Orebro">Örebro</option>
              <option value="Orkelljunga">Örkelljunga</option>
              <option value="Ornskoldsvik">Örnskoldsvik</option>
              <option value="Ostersund">Östersund</option>
              <option value="Osteraker">Österaker</option>
              <option value="Osthammar">Östhammar</option>
              <option value="Ostra Goinge">Östra Goinge</option>
              <option value="Overkalix">Överkalix</option>
              <option value="Overtornea">Övertornea</option>
            </select>
          </>
        )}
        </div>
        
        {!state.anon ? (
          <div className="muni-checkbox-container">
            <input
              name="gdpr"
              className="muni-checkbox"
              type="checkbox"
              checked={state.gdpr}
              onChange={(e) => setState({ ...state, gdpr: !state.gdpr })}
            />
            <label htmlFor="gdpr">
              {tn.lang.iApprove}
              <span
                style={{ textDecoration: "underline" }}
                onClick={() => setState({ ...state, showPrivacyPolicy: true })}
              >
                {tn.lang.terms}
              </span>
            </label>
          </div>
        ) : null}
      

        <button
              className="muni-login-register-button"
              style={{ backgroundColor: valid ? "#7A527A" : "#DF6158" }}
              onClick={() => {
                if (valid && !state.anon) {
                  context.createUser({
                    email: state.email.trim(),
                    firstName: state.firstName.trim(),
                    lastName: state.sirName.trim(),
                    region: state.muni.trim(),
                    newPassword: state.password.trim(),
                    campaign: state.code.trim(),
                  })
                } else if (valid && state.anon) {
                  context.loginAnon(state.code.trim())
                }
              }}
            >
              {tn.lang.register}
        </button>

        <label className="muni-login-label" onClick={() => props.openLoginModal()}>
          {tn.lang.alreadyAcc} <span className="muni-login-label-link">{tn.lang.logIn}</span> 
        </label>
        
        <div
        style={
          state.showPrivacyPolicy
            ? {
                position: "absolute",
                top: 0,
                backgroundColor: "#FFFFFF",
                left: 0,
              }
            : { display: "none" }
        }
      >
        <div>Varannan Vecka’s privacy policy</div>
        <div style={{ wordBreak: "break-word" }}>
          {tn.getLanguage() === "EN" ? terms : terms_se}
        </div>
        <div style={{ position: "fixed", bottom: 0, right: 0 }}>
          <button
            className="muni-register-button"
            onClick={() => setState({ ...state, showPrivacyPolicy: false })}
            color="primary"
          >
            {tn.lang.ok}
          </button>
        </div>
      
      </div>
    </form>
  </div>
  )
}
