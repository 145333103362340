import { IContext, LoginContext } from "Context/LoginContext"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import LandingPage from "./LandingPage"
import PanelPage from "./PanelPage"
import "pages/styles/statusmsg.css"

class Router extends React.Component {
  public render() {
    const context: IContext = this.context

    let loggedIn: boolean = false
    if (context.loggedIn === "IN_PROGRESS") {
      return <div>Logging in</div>
    } else if (context.loggedIn === "LOGGED_IN") {
      loggedIn = true
    } else if (context.loggedIn === "NOT_LOGGED_IN") {
      loggedIn = false
    }

    return (
      <>
        <Switch>
          {loggedIn ? (
            <Redirect exact from={"/"} to={"/panel"} />
          ) : (
            <Redirect from={"/panel"} to={"/"} />
          )}
          <Route exact path="/" component={LandingPage} />
          <Route path="/panel" component={PanelPage} />
        </Switch>
        <div
          style={{
            width: "100vw",
            position: "fixed",
            bottom: "0px",
            pointerEvents: "none",
          }}
        >
          <div
            className="muni-statusmsg"
            style={{ bottom: context.statusDisplay ? 10 : -100 }}
          >
            {context.statusMsg}
          </div>
        </div>
      </>
    )
  }
}

Router.contextType = LoginContext

export default Router
