import React from "react"
import "components/articles/articleStyles/articlecard.css"
import Translate from "service/Translate"
import { exercies } from "components/exercises/Exercises"

const tn: Translate = Translate.getInstance()

export interface IArticleCard {
  title: string
  text: string
  src?: string
  read: boolean
  slug: string
}

const ArticleCard = (props: IArticleCard) => {
  const { title, text, src, read, slug } = props

  let hasExercise = false
  for (const exercise of exercies) {
    if (exercise.articles.indexOf(slug) > -1) {
      hasExercise = true
      break
    }
  }

  return (
    <div className="muni-articleholder">
      {read ? (
        <div className="muni-readlogo">
          <div className="muni-readlogo-check" />
        </div>
      ) : null}
      <div className="muni-articlecard">
        <div
          className="muni-articleimage"
          style={{ backgroundImage: "url(" + src + ")" }}
        />
        <div className="muni-articlecontent">
          <div
            className="muni-articletitle"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div
            className="muni-articletext"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <div className="muni-articlereadmore">{tn.lang.readMore}</div>
        </div>

        {hasExercise ? (
          <img
            className={`exercise-badge ${hasExercise ? "has-exercise" : ""}`}
            src={require("../../images/task.png")}
            alt="Icon"
          />
        ) : null}
      </div>
    </div>
  )
}

export default ArticleCard
