import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:string,
}

export const ExerciseChildSchoolwork_title = "När skolarbetet påverkas";
export const ExerciseChildSchoolwork_title_en = "When your child struggles to keep up at school";
export const ExerciseChildSchoolwork_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/skola-1.jpg?fit=507%2C338&ssl=1";

const baselanguage = {

  introOne: "Oro och stress hos barn kan påverka inlärningsförmågan och fokus i skolan.",
  introTwo: "Det är bra om föräldrar har extra tålamod och hjälper till med läxor, skollogistik.",
  introThree: "Barn behöver få känna att de lyckas när de anstränger sig. Hjälp barnet göra en realistisk plan!",
  introFour: "Ta hjälp av skolan vid behov",
  introFive: "Har ni ett okej samarbete om barnet så att i kan dela information och hjälpas åt betyder det mycket för barnet.",
  
  outro: "Ett sätt att få in rutiner kring läxorna är att lägga in dem i Varannan Vecka appen under Uppgifter, eller kanske som en återkommande gemensam händelse på vissa dagar/tider i Översikten. Både barnet och du får då en påminnelse när det är dags att göra läxan.",

  questionOne :"Kan du prata med medföräldern och hjälpas åt genom likartade rutiner eller mer informationsutbyte? Vad är viktigt att diskutera i sådana fall?",
}

const engelska: (typeof baselanguage) = {
  introOne: "Anxiety and stress in children can affect learning ability and focus in school.",
  introTwo: "It's good if parents have extra patience and help with homework and school logistics.",
  introThree: "Children need to feel that they succeed when they strive. Help the child make a realistic plan!",
  introFour: "Seek help from the school when needed.",
  introFive: "If you have a good cooperation with the child's other parent, sharing information and helping each other means a lot for the child.",
 
  outro: "One way to establish routines around homework is to add them to the Every Other Week app under Tasks, or perhaps as a recurring shared event on certain days/times in the Overview. Both the child and you will then receive a reminder when it's time to do the homework.",

  questionOne :"Can you talk to the co-parent and help the child with similar routines or more information exchange? If so, what is important to discuss?",
}

export default function ExerciseChildSchoolwork(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false,
    reflectionOne:"",
  });
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <ul>
        <li>{lang.introOne}</li>
        <li>{lang.introTwo}</li>
        <li>{lang.introThree}</li>
        <li>{lang.introFour}</li>
      </ul>
      <h2>{lang.outro}</h2>
      <h2>{lang.questionOne}</h2>
      <textarea rows={5} name="reflectionOne" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionOne} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.outro}</h2>
      <h2>{lang.questionOne}</h2>
      {data.reflectionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionOne}</div>):null}
    </>);

  return renderToStaticMarkup(reply);
}