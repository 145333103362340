import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:string,
}

export const ExerciseCheckIn_title = "Vad behöver jag?";
export const ExerciseCheckIn_title_en = "Ckeck in with yourself";
export const ExerciseCheckIn_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2022/09/istockphoto-1164161587-170667a.jpg?fit=508%2C339&ssl=1";

const baselanguage = {
  intro: 'Tänk på din vardag som den är just nu. Gör upp en miniplan för hur du ska ta hand om dig själv.',
  questionOne: 'Om du var din egen bästa vän vilket råd skulle du ge dig själv just nu?',
}

const engelska: (typeof baselanguage) = {
  intro: 'Think about your daily life as it is now. Make a mini plan for how you will take care of yourself.',
  questionOne: 'If you were your own best friend, what advice would you give yourself right now?',
}

export default function ExerciseCheckIn(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false,
    reflectionOne:"",
  });
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <p>{lang.intro}</p>
      <h2>{lang.questionOne}</h2>
      <textarea rows={5} name="reflectionOne" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionOne} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      {data.reflectionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionOne}</div>):null}
    </>);

  return renderToStaticMarkup(reply);
}