import React from "react"
import Translate from "service/Translate"
import "./landingStyles/landingYoutube.css"

import YoutubeEmbed from "components/landing/YoutubeEmbed"

const tn: Translate = Translate.getInstance()

export default function LandingYoutube() {
  const redirectToAppDownload = () => {
    window.location.href = "https://varannanvecka.app/install/kommun"
  }

  const redirectToVarannanVecka = () => {
    window.location.href = "https://www.varannanvecka.app/"
  }

  return (
    <div className="muni-intro-next-step">
      <div className="muni-intro-next-step-container">

        <div className="muni-intro-next-step-headline">
          <img className="muni-intro-next-step-headline-ipad-phone" src="./vappipadmobile.png" alt="varannan vecka app"></img>
          <img className="muni-intro-next-step-headline-ipad" src="./kalender.png" alt="varannan vecka app"></img>
          <div className="muni-intro-next-step-headline-content">
            <h3>{tn.lang.youtubeExplain}</h3>
            <p>{tn.lang.youtubeText}</p>
            
            <div className="muni-intro-next-step-text-list"> 
                    <div className="muni-intro-next-step-text-list-item">
                        <img
                          src="./check_300.svg"
                          alt="checkbox checked"
                          className="checkbox-checked-img"
                                />
                        <p>{tn.lang.upsText1}
                        </p>
                    </div>

                    <div className="muni-intro-next-step-text-list-item">
                        <img
                          src="./check_300.svg"
                          alt="checkbox checked"
                          className="checkbox-checked-img"
                                />
                        <p>{tn.lang.upsText2}
                        </p>
                    </div>

                    <div className="muni-intro-next-step-text-list-item">
                        <img
                          src="./check_300.svg"
                          alt="checkbox checked"
                          className="checkbox-checked-img"
                                />
                        <p>{tn.lang.upsText3}
                        </p>
                    </div>

                    <div className="muni-intro-next-step-text-list-item">
                        <img
                          src="./check_300.svg"
                          alt="checkbox checked"
                          className="checkbox-checked-img"
                                />
                        <p>{tn.lang.upsText4}
                        </p>
                    </div>
                  </div>
  
            <img className="muni-intro-next-step-headline-phone" src="./phoneOverview.png" alt="varannan vecka app"></img>
            <div className="muni-intro-next-step-button-container">
              <button
                className="muni-intro-next-step-app-button"
                onClick={redirectToAppDownload}
              >
                {tn.lang.visitApp}
              </button>

              <button
                className="muni-intro-next-step-site-button"
                onClick={redirectToVarannanVecka}
              >
                {tn.lang.visitAppSite}

                <img
                  src="./arrow-forward-red.svg"
                  alt="pil pekar mot höger"
                  className="muni-arrow-svg-img"
                        />
              </button>
            </div> 
          </div>
          
        </div>
          {/*  <div className="muni-intro-next-step-section">
                <div className="muni-intro-next-step-section-item">
                  <img className="muni-intro-next-step-headline-ipad" src="./phonePacking.png" alt="varannan vecka app"></img>
                  <div className="muni-intro-next-step-text-list"> 
                    <div className="muni-intro-next-step-text-list-item">
                        <img
                          src="./check_300.svg"
                          alt="checkbox checked"
                          className="checkbox-checked-img"
                                />
                        <p>Allt om barnen på samma plats.
                        </p>
                    </div>

                    <div className="muni-intro-next-step-text-list-item">
                        <img
                          src="./check_300.svg"
                          alt="checkbox checked"
                          className="checkbox-checked-img"
                                />
                        <p>Låt appen föra dialogen, påminna och informera. 
                        </p>
                    </div>

                    <div className="muni-intro-next-step-text-list-item">
                        <img
                          src="./check_300.svg"
                          alt="checkbox checked"
                          className="checkbox-checked-img"
                                />
                        <p>Packlistor och påminnelser som hjälper dig att få järnkoll.
                        </p>
                    </div>
                  </div>
                </div>

                <div className="muni-intro-next-step-section-item">
                  <img className="muni-intro-next-step-headline-ipad" src="./phoneNotification.png" alt="varannan vecka app"></img>
                  <div className="muni-intro-next-step-text-list">
                    <div className="muni-intro-next-step-text-list-item">
                        <img
                          src="./check_300.svg"
                          alt="checkbox checked"
                          className="checkbox-checked-img"
                                />
                        <p>Dela utgifter och kostader direkt i appen.
                        </p>
                    </div>
                    <div className="muni-intro-next-step-text-list-item">
                        <img
                          src="./check_300.svg"
                          alt="checkbox checked"
                          className="checkbox-checked-img"
                                />
                        <p>Få hjälp och rådgivning av våra experter 
                        </p>
                    </div>
                    <div className="muni-intro-next-step-text-list-item">
                        <img
                          src="./check_300.svg"
                          alt="checkbox checked"
                          className="checkbox-checked-img"
                                />
                        <p>Smarta bytesscheman och semesterplanering. 
                        </p>
                    </div>
                  </div>
                </div>
          </div> */}
          <div className="muni-intro-next-step-movie">
            <YoutubeEmbed embedId="ObcO68ReSbg"/>
          </div>
        </div>
      </div>
  )
}





{/* <div className="muni-intro-next-step-content">
          <div className="muni-intro-next-step-ups">
            <h2 className="muni-intro-next-step-ups-quotes" id="muni-intro-next-step-ups-quotes-bold">"Vändningen kom med appen Varannan Vecka. <span id="muni-intro-next-step-ups-quotes-thin"> Det är mindre tjafs och båda har bättre koll."</span></h2>  
            
            <img className="muni-intro-next-step-up-ratings" src="https://www.varannanvecka.app/wp-content/uploads/2022/10/appstore4.4.png" alt="ratings" />
          </div>
        </div>   */}

{/* <div className="muni-intro-next-step-frames">
              <img className="muni-intro-next-step-img" src="./iphone1.png" alt="mobileview of app" />
              <img className="muni-intro-next-step-img" src="./iphone2.png" alt="mobileview of app" />
              <img className="muni-intro-next-step-img" src="./iphone3.png" alt="mobileview of app" />
          </div>   */}