import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,
  reflectionOne:string,
  optionOne:boolean,
  optionTwo:boolean,
  optionThree:boolean,
  optionFour:boolean,
  optionFive:boolean,
  optionSix:boolean,
}

export const ExerciseLifeChanges_title = "Vad ger dig energi och ro?";
export const ExerciseLifeChanges_title_en = "Self care alternatives";
export const ExerciseLifeChanges_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/istockphoto-873316616-170667a.jpg?fit=509%2C339&ssl=1";

const baselanguage = {
  intro: 'I artikeln "Så kan vi ta hand om oss själva i en kris" framgår vad vi kan göra för att visa omtanke om oss själva. Klicka i de alternativ som du tror skulle vara bra för dig.',
  optionOne : "Prata med någon om mina upplevelser",
  optionTwo : "Träning och rörelse",
  optionThree : "Äta mer av det du tycker är gott",
  optionFour : "Mer sömn och vila",
  optionFive : "Dra ner på alkohol och nikotin",
  optionSix : "Umgås med vänner och familj",

  questionOne: 'Om du tänker på dig och själv en stund, bara dig själv som person, inte som förälder eller partner (eller ex-partner) vad skulle du vilja göra just nu? Något som skulle göra dig glad/trygg/piggare/mer harmonisk?',
  outro: 'Om det finns praktiska möjligheter, försök ge dig själv det du tänkte på. ',
}

const engelska: (typeof baselanguage) = {
  intro: 'In the article "How we can take care of ourselves," it explains what we can do to show care for ourselves. Click on the options that you think would be good for you.',
  optionOne : "Talk to someone about my experiences.",
  optionTwo : "Work out",
  optionThree : "Eat more of what you like",
  optionFour : "More sleep and rest",
  optionFive : "Cut down on alcohol and nicotine",
  optionSix : "Spend time with friends and family",

  questionOne: 'If you were thinking about yourself, just yourself as a person, not as a parent or partner (or expartner) what would you want to do right now? Something that would make you happy/feel more safe/more alert/more harmonious?',
  outro: 'If you have the opportunity, try to give yourself what you wrote down.',
}

export default function ExerciseLifeChanges(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false,
    reflectionOne:"",
    optionOne:false,
    optionTwo:false,
    optionThree:false,
    optionFour:false,
    optionFive:false,
    optionSix:false,
  });
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const handleChange = (name:string, value:boolean) => {
    setState({ ...state, [name]: value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <p>{lang.intro}</p>
      <div className="muni-exercise-clickanswer-box">
        <div className={("muni-exercise-clickanswer"+((state.optionOne)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("optionOne", !state.optionOne)}>{lang.optionOne}</div>
        <div className={("muni-exercise-clickanswer"+((state.optionTwo)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("optionTwo", !state.optionTwo)}>{lang.optionTwo}</div>
        <div className={("muni-exercise-clickanswer"+((state.optionThree)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("optionThree", !state.optionThree)}>{lang.optionThree}</div>
        <div className={("muni-exercise-clickanswer"+((state.optionFour)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("optionFour", !state.optionFour)}>{lang.optionFour}</div>
        <div className={("muni-exercise-clickanswer"+((state.optionFive)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("optionFive", !state.optionFive)}>{lang.optionFive}</div>
        <div className={("muni-exercise-clickanswer"+((state.optionSix)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("optionSix", !state.optionSix)}>{lang.optionSix}</div>
      </div>
      <h2>{lang.questionOne}</h2>
      <textarea rows={5} name="reflectionOne" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionOne} onChange={(e) => handleChangeTA(e)}></textarea>
      <p>{lang.outro}</p>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      {data.optionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.optionOne}</div>):null}
      {data.optionTwo?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.optionTwo}</div>):null}
      {data.optionThree?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.optionThree}</div>):null}
      {data.optionFour?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.optionFour}</div>):null}
      {data.optionFive?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.optionFive}</div>):null}
      {data.optionSix?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.optionSix}</div>):null}
      {data.reflectionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionOne}</div>):null}
    </>);

  return renderToStaticMarkup(reply);
}