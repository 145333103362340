import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"
import FlipCard from './FlipCard';

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:boolean;
  reflectionTwo:boolean;
  reflectionThree:boolean;
  reflectionFour:boolean;
  reflectionFive:boolean;
  reflectionSix:boolean;
  reflectionSeven:boolean;
  reflectionEight:boolean;
  reflectionNine:boolean;
  reflectionTen:boolean;
  reflectionEleven:string;
}

export const ExerciseHowAreYou_title = "Hur mår du just nu?  ";
export const ExerciseHowAreYou_title_en = "How are you right now?";
export const ExerciseHowAreYou_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/kris.jpg?fit=553%2C311&ssl=1";

const baselanguage = {
  questionOne: 'I artikeln som du nyss läst listades exempel på vanliga krisreaktioner. Stämmer några av dessa på dig? Om ja, markera gärna vilken/vilka',
  intro: 'Så här berättar en förälder om sina upplevelser: ',
  quote: '”Jag vet att vi kommer att fortsätta funka som föräldrar och jag vet att det kommer att bli okej längre fram, men just nu finns det ingen tröst. Jag har inte tänkt en klar tanke på flera månader och är rädd för de mest idiotiska saker. Vaknar kallsvettig ur en dröm där Veronika flyttat till Borås med barnen och lämnat mig ensam kvar i den tomma villan. På jobbet är alla förstående och snälla, men ändå är jag rädd för att få sparken. Jag får ju ingenting gjort! I fredags reste jag mig mitt under ett möte och bara gick ut. Rastlösheten rasar inom mig och jag kan inte koncentrera mig på någonting. Att det är snö på vindrutan eller att en glödlampa går sönder känns som oöverstigliga problem. När jag ser mig själv i spegeln ser jag ut ungefär som vanligt, men inuti är det som en centrifug där allting bara går runt.”',
  introTwo: 'Vad tänker du om det som föräldern berättar? Känner du kanske igen dig? Upplever du helt andra känslor, kanske av lättnad?',
  questionTwo: 'Skriv gärna ned din egen berättelse med några meningar. Dina ord ses bara av dig och syftar till självreflektion. Om några månader kan det vara hjälpsamt att gå in under Mina sidor och läsa det du skriver idag. Det ger ofta perspektiv och värdefulla insikter.',

  reflectionOne:'Hopplöshetskänslor',
  reflectionTwo: 'Panik',
  reflectionThree:'Rastlöshet',
  reflectionFour: 'Känslomässig avstängdhet',
  reflectionFive: 'Sömnstörningar',
  reflectionSix: 'Ilska och irritabilitet',
  reflectionSeven: 'Att dra sig undan socialt',
  reflectionEight: 'Koncentrationssvårigheter',
  reflectionNine: 'Svårigheter att komma ihåg',
  reflectionTen: 'Förvirring',

}

const engelska: (typeof baselanguage) = {
  questionOne: 'In the article you just read, examples of common crisis responses were listed. Do any of these apply to you? If yes, please mark which one(s).',
  intro: 'This is how a parent talks about his experiences: ',
  quote: "”I know we will continue to function as parents and I know it will be okay in the future, but right now there is no comfort. I haven't thought a clear thought in months and am afraid of the most idiotic things. Waking up in a cold sweat from a dream where Veronika has moved to Borås with the children and left me alone in the empty villa. At work, everyone is understanding and kind, but still I'm afraid of getting fired. I don't get anything done! Last Friday I got up in the middle of a meeting and just walked out. The restlessness rages inside me and I can't concentrate on anything. That there is snow on the windshield or that a light bulb breaks feel like insurmountable problems. When I look at myself in the mirror, I look pretty much the same as usual, but inside it's like a centrifuge where everything just spins around.”",
  introTwo: 'What do you think about what the parent says? Do you perhaps recognize yourself? Do you experience completely different feelings, perhaps of relief?',  questionTwo: 'Feel free to write down your own story with a few sentences. Your words are seen only by you and are intended for self-reflection. In a few months, it may be helpful to go under My pages and read what you write today. It often provides perspective and valuable insights.',

  reflectionOne:'Feelings of hopelessness',
  reflectionTwo: 'Panic',
  reflectionThree:'Restlessness',
  reflectionFour: 'Emotional withdrawal',
  reflectionFive: 'Sleep disorders',
  reflectionSix: 'Anger and irritability',
  reflectionSeven: 'Withdrawing socially',
  reflectionEight: 'Difficulty concentrating',
  reflectionNine: 'Difficulty remembering',
  reflectionTen: 'Confusion',
}

export default function ExerciseHowAreYou(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false,
    reflectionOne:false,
    reflectionTwo:false,
    reflectionThree:false,
    reflectionFour:false,
    reflectionFive:false,
    reflectionSix:false,
    reflectionSeven:false,
    reflectionEight:false,
    reflectionNine:false,
    reflectionTen:false,
    reflectionEleven:"",
  });
  const handleChange = (name:string, value:boolean) => {
    setState({ ...state, [name]: value });
  };
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <h2>{lang.questionOne}</h2>
      <div className="muni-exercise-flip-box">

        <FlipCard 
          front={lang.reflectionOne+"?"}
          back={lang.reflectionOne+"."}
          showSide={state.reflectionOne?'back':'front'}
          onClick={() =>{handleChange("reflectionOne",!state.reflectionOne);}}
        />
        <FlipCard 
          front={lang.reflectionTwo+"?"}
          back={lang.reflectionTwo+"."}
          showSide={state.reflectionTwo?'back':'front'}
          onClick={() =>{handleChange("reflectionTwo",!state.reflectionTwo);}}
        />
        <FlipCard 
          front={lang.reflectionThree+"?"}
          back={lang.reflectionThree+"."}
          showSide={state.reflectionThree?'back':'front'}
          onClick={() =>{handleChange("reflectionThree",!state.reflectionThree);}}
        />
        <FlipCard 
          front={lang.reflectionFour+"?"}
          back={lang.reflectionFour+"."}
          showSide={state.reflectionFour?'back':'front'}
          onClick={() =>{handleChange("reflectionFour",!state.reflectionFour);}}
        />
        <FlipCard 
          front={lang.reflectionFive+"?"}
          back={lang.reflectionFive+"."}
          showSide={state.reflectionFive?'back':'front'}
          onClick={() =>{handleChange("reflectionFive",!state.reflectionFive);}}
        />
        <FlipCard 
          front={lang.reflectionSix+"?"}
          back={lang.reflectionSix+"."}
          showSide={state.reflectionSix?'back':'front'}
          onClick={() =>{handleChange("reflectionSix",!state.reflectionSix);}}
        />
        <FlipCard 
          front={lang.reflectionSeven+"?"}
          back={lang.reflectionSeven+"."}
          showSide={state.reflectionSeven?'back':'front'}
          onClick={() =>{handleChange("reflectionSeven",!state.reflectionSeven);}}
        />
        <FlipCard 
          front={lang.reflectionEight+"?"}
          back={lang.reflectionEight+"."}
          showSide={state.reflectionEight?'back':'front'}
          onClick={() =>{handleChange("reflectionEight",!state.reflectionEight);}}
        />
        <FlipCard 
          front={lang.reflectionNine+"?"}
          back={lang.reflectionNine+"."}
          showSide={state.reflectionNine?'back':'front'}
          onClick={() =>{handleChange("reflectionNine",!state.reflectionNine);}}
        />
        <FlipCard 
          front={lang.reflectionTen+"?"}
          back={lang.reflectionTen+"."}
          showSide={state.reflectionTen?'back':'front'}
          onClick={() =>{handleChange("reflectionTen",!state.reflectionTen);}}
        />
      </div>
      <p style={{marginTop: '50px'}}>{lang.intro}</p>
      <p style={{margin: '20px', fontSize: '15px', fontStyle: 'italic'}} >{lang.quote}</p>
      <p>{lang.introTwo}</p>

      <h2>{lang.questionTwo}</h2>
      <textarea rows={5} name="reflectionEleven" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionEleven} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      {data.reflectionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionOne}</div>):null}
      {data.reflectionTwo?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionTwo}</div>):null}
      {data.reflectionThree?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionThree}</div>):null}
      {data.reflectionFour?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionFour}</div>):null}
      {data.reflectionFive?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionFive}</div>):null}
      {data.reflectionSix?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionSix}</div>):null}
      {data.reflectionSeven?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionSeven}</div>):null}
      {data.reflectionEight?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionEight}</div>):null}
      {data.reflectionNine?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionNine}</div>):null}
      {data.reflectionTen?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionTen}</div>):null}
      <h2>{lang.questionTwo}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionEleven}</div>
    </>);

  return renderToStaticMarkup(reply);
}