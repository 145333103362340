import React from "react"
import Translate from "service/Translate"
import "./landingStyles/landingIntro.css"

const tn: Translate = Translate.getInstance()

export default function LandingIntro() {
  const openLoginModal = () => {
    const event = new CustomEvent("openSignupModal")
    window.dispatchEvent(event)
  }

  const scrollToLandingForWork = () => {
    setTimeout(() => {
      const landingForWorkSection = document.querySelector(
        ".landing-for-work-class"
      ) as HTMLElement

      if (landingForWorkSection) {
        landingForWorkSection.scrollIntoView({ behavior: "smooth" })
      }
    }, 300)
  }

  return (
    <div className="muni-landing-intro-page">
      <div className="muni-landing-intro-container">
        <h1 className="muni-intro-tagline-h1">
          {tn.lang.vvintroWelcomeAppName}
        </h1>
        <div className="muni-intro-button-container">
          <button className="muni-intro-button" onClick={openLoginModal}>
            {tn.lang.getStarted}
          </button>

          <button
            className="muni-intro-button-professionell"
            onClick={scrollToLandingForWork}
          >
            {tn.lang.professionelButton}
            <img
              src="./arrow-forward.svg"
              alt="pil pekar mot höger"
              className="muni-arrow-svg-img"
            />
          </button>
        </div>
      </div>
    </div>
  )
}
